import { useMemo } from 'react'

/**
 * Transforms @model into a view-model with the help of @transformer.
 * @param model {object}: model to transform.
 * @param transformer {function(object)}: transforms model into view-model. @model is passed as the first parameter.
 * @returns {object}: desired view model
 */
const useViewModel = (model, transformer) => useMemo(
  () => transformer(model),
  [model, transformer]
)

export default useViewModel
