import {
  SEND_FORGET_PASSWORD_EMAIL,
  UPDATE_AUTH_USER,
  UPDATE_EDIT_PROFILE_DIALOG_OPEN,
  UPDATE_LOAD_USER
} from '../../@jumbo/constants/ActionTypes'
import { makeActionCreator } from '../../utilities/store'
import AUTH_TYPES from '../types/Auth'

export const setAuthUser = user => ({
  type: UPDATE_AUTH_USER,
  payload: user
})

export const updateLoadUser = loading => ({
  type: UPDATE_LOAD_USER,
  payload: loading
})

export const setForgetPassMailSent = status => ({
  type: SEND_FORGET_PASSWORD_EMAIL,
  payload: status
})

export const updateEditProfileDialogOpen = dialogOpen => ({
  type: UPDATE_EDIT_PROFILE_DIALOG_OPEN,
  payload: dialogOpen
})

export const login = makeActionCreator(AUTH_TYPES.LOGIN, 'email', 'password')

export const setTwoFactorAuthenticationInfo = makeActionCreator(AUTH_TYPES.SET_TWO_FACTOR_AUTHENTICATION_INFO, 'twoFactorAuth')

export const sendAuthCode = makeActionCreator(AUTH_TYPES.SEND_AUTH_CODE, 'authCode')

export const authorize = makeActionCreator(AUTH_TYPES.AUTHORIZE)

export const logout = makeActionCreator(AUTH_TYPES.LOGOUT)

export const resetPassword = makeActionCreator(AUTH_TYPES.RESET_PASSWORD, 'email')
