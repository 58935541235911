import { combineReducers } from 'redux'

import Common from './Common'
import Auth from './Auth'
import Users from './Users'
import AgentProcesses from './AgentProcesses'
import Agents from './Agents'
import Processes from './Processes'
import AgentJails from './AgentJails'
import AgentActivities from './AgentActivities'
import ExecutedCommands from './ExecutedCommands'

export default () =>
  combineReducers({
    common: Common,
    auth: Auth,
    users: Users,
    agentProcesses: AgentProcesses,
    agents: Agents,
    processes: Processes,
    agentJails: AgentJails,
    agentActivities: AgentActivities,
    executedCommands: ExecutedCommands
  })
