import webSocketClient from '../clients/webSocketClient'
import { WS_API_EVENTS } from '../constants/api'
import { withStandardWSResponseHandling } from '../utilities/responseHandling'

const RequestJails = async () => webSocketClient.emitAsync(WS_API_EVENTS.OUTGOING.IP_REQUEST_ALL)

const UnbanIP = async (agentId, jail, ip) =>
  webSocketClient.emitAsync(
    WS_API_EVENTS.OUTGOING.IP_UNBAN,
    agentId,
    jail,
    ip
  )

const OnUpdatedMany = listener => webSocketClient.socket.on(WS_API_EVENTS.INCOMING.IP_UPDATED_MANY, listener)

const OffUpdatedMany = listener => webSocketClient.socket.off(WS_API_EVENTS.INCOMING.IP_UPDATED_MANY, listener)

const IPManagementService = {
  RequestJails: withStandardWSResponseHandling(RequestJails),
  UnbanIP: withStandardWSResponseHandling(UnbanIP),
  OnUpdatedMany,
  OffUpdatedMany
}

export default IPManagementService
