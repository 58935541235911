import { all, fork, put, takeLatest } from 'redux-saga/effects'
import WS_EVENTS_TYPES from '../types/WSEvents'
import { eventSubscriptionToSaga, SagaUpdateChannelStore } from '../../utilities/store/sagas'
import { setCurrentAgent, updateLocalAgent } from '../actions/Agents'
import AgentService from '../../services/Agent'
import { fetchStart, fetchSuccess } from '../actions'
import SUCCESS_CODES from '../../constants/successCodes'
import { handleRemoteApiError } from '../../utilities/responseHandling'
import AGENTS_TYPES from '../types/Agents'

function * updateAgent (action) {
  try {
    yield put(fetchStart('updateAgent'))
    const { data: { agent, callback } } = action
    yield AgentService.Update(agent.id, agent)
    yield put(fetchSuccess(SUCCESS_CODES.SUCCESS, 'updateAgent'))
    yield put(setCurrentAgent(null))
    callback && callback()
  } catch (e) {
    yield handleRemoteApiError(e, 'updateAgent', 'Failed to update agent')
  }
}

function * handleAgentUpdate (data) {
  yield put(updateLocalAgent(data))
}

const updateChannelStore = new SagaUpdateChannelStore()

function * subscribeToWSEvents () {
  yield all([
    fork(
      eventSubscriptionToSaga,
      AgentService.OnAgentUpdated,
      AgentService.OffAgentUpdated,
      handleAgentUpdate,
      updateChannelStore.AddUpdateChannel
    )
  ])
}

function * unsubscribeFromWSEvents () {
  yield updateChannelStore.CloseUpdateChannels()
}

function * AgentsSaga () {
  yield takeLatest(AGENTS_TYPES.UPDATE_AGENT, updateAgent)
  yield takeLatest(WS_EVENTS_TYPES.SUBSCRIBE, subscribeToWSEvents)
  yield takeLatest(WS_EVENTS_TYPES.UNSUBSCRIBE, unsubscribeFromWSEvents)
}

export default AgentsSaga
