// some types are defined inside @jumbo/constants/ActionTypes.js. We leave them there for compatibility reasons.
const AUTH_TYPES = {
  LOGIN: '[auth] login',
  SET_TWO_FACTOR_AUTHENTICATION_INFO: '[auth] setTwoFactorAuthenticationInfo',
  SEND_AUTH_CODE: '[auth] sendAuthCode',
  AUTHORIZE: '[auth] authorize',
  LOGOUT: '[auth] logout',
  RESET_PASSWORD: '[auth] resetPassword'
}

export default AUTH_TYPES
