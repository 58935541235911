import { put, select, takeLatest } from 'redux-saga/effects'
import AUTH_TYPES from '../types/Auth'
import { fetchStart, fetchSuccess, fetchError } from '../actions'
import AuthService from '../../services/auth'
import { handleHTTPError } from '../../utilities/responseHandling'
import { AUTH_TOKEN_LOCAL_STORAGE_KEY } from '../../constants/core'
import {
  setAuthUser,
  updateLoadUser,
  authorize as authorizeAction,
  setForgetPassMailSent,
  setTwoFactorAuthenticationInfo
} from '../actions/Auth'
import { authorizeHttpClient, unAuthorizeHttpClient } from '../../clients/httpClient'
import webSocketClient from '../../clients/webSocketClient'
import { subscribeToWSEvents, unsubscribeFromWSEvents } from '../actions/WSEvents'
import SUCCESS_CODES from '../../constants/successCodes'
import { resetStore } from '../actions/Global'
import { selectTwoFactorAuthInfo } from '../selectors/Auth'
import ERROR_CODES from '../../constants/errorCodes'

function * login (action) {
  try {
    yield put(fetchStart('login'))
    const { data: { email, password } } = action
    const loginResult = yield AuthService.Login(email, password)

    yield put(setTwoFactorAuthenticationInfo(loginResult))
    yield put(fetchSuccess(null, 'login'))
  } catch (e) {
    yield handleHTTPError(e, 'login', 'Failed login attempt')
  }
}

function * sendAuthCode (action) {
  try {
    yield put(fetchStart('sendAuthCode'))
    const { data: { authCode } } = action
    const { controlCode, sub } = yield select(selectTwoFactorAuthInfo)
    const loginResult = yield AuthService.SendAuthCode(controlCode, authCode, sub)
    console.log()
    if (loginResult.token) { // auth code was correct. user can be logged in
      localStorage.setItem(AUTH_TOKEN_LOCAL_STORAGE_KEY, loginResult.token)
      yield put(authorizeAction())
      yield put(fetchSuccess(SUCCESS_CODES.SUCCESS, 'sendAuthCode'))
    } else { // auth code was incorrect. auth code is regenerated
      yield put(setTwoFactorAuthenticationInfo(loginResult))
      yield put(fetchError(ERROR_CODES.INVALID_AUTH_CODE, 'sendAuthCode'))
    }
  } catch (e) {
    yield handleHTTPError(e, 'sendAuthCode', 'Failed login attempt')
  }
}

function * authorize () {
  try {
    yield put(fetchStart('authorize'))

    const token = localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_KEY)
    if (token) {
      // authorize http client
      authorizeHttpClient(token)
      // init websocket client
      yield webSocketClient.connect(token)
      yield put(subscribeToWSEvents())
      // make sure, that authentication is correct by getting the auth user
      const authUser = yield AuthService.GetAuthUser()
      // signal auth success to the app by setting the authenticated user
      yield put(setAuthUser(authUser))
    }

    yield put(fetchSuccess(undefined, 'authorize'))
  } catch (e) {
    yield handleHTTPError(e, 'authorize', 'Failed authorization')
    unAuthorizeHttpClient()
    webSocketClient.disconnect()
  }

  yield put(updateLoadUser(true))
}

function * logout () {
  try {
    yield put(fetchStart('logout'))
    // logout on server
    yield AuthService.Logout()

    // delete auth token
    localStorage.removeItem(AUTH_TOKEN_LOCAL_STORAGE_KEY)
    // remove auth token from httpClient
    unAuthorizeHttpClient()
    // remove ws event subscriptions
    yield put(unsubscribeFromWSEvents())
    // disconnect socket
    webSocketClient.disconnect()
    // reset the redux store
    yield put(resetStore())

    yield put(fetchSuccess(SUCCESS_CODES.SUCCESS, 'logout'))
  } catch (e) {
    yield handleHTTPError(e, 'logout', 'Failed logout attempt')
  }
}

function * resetPassword (action) {
  try {
    yield put(fetchStart('resetPassword'))
    const { email } = action.data
    yield AuthService.ResetPassword(email)

    yield put(setForgetPassMailSent(true))
    yield put(fetchSuccess(undefined, 'resetPassword'))
  } catch (e) {
    yield handleHTTPError(e, 'resetPassword', 'Failed password reset')
  }
}

function * AuthSaga () {
  yield takeLatest(AUTH_TYPES.LOGIN, login)
  yield takeLatest(AUTH_TYPES.SEND_AUTH_CODE, sendAuthCode)
  yield takeLatest(AUTH_TYPES.AUTHORIZE, authorize)
  yield takeLatest(AUTH_TYPES.LOGOUT, logout)
  yield takeLatest(AUTH_TYPES.RESET_PASSWORD, resetPassword)
}

export default AuthSaga
