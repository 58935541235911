export const createReducer = (initialState, handlers) => (state = initialState, action) => {
  if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
    return handlers[action.type](state, action)
  } else {
    return state
  }
}

export const makeActionCreator = (type, ...argNames) => (...args) => {
  const action = { type, data: {} }
  argNames.forEach((arg, index) => {
    action.data[argNames[index]] = args[index]
  })
  return action
}

export const commonItemMatchers = {
  /**
   * Returns true if item1 equals item2(===).
   * @param item1
   * @param item2
   * @returns {boolean}
   */
  equals: (item1, item2) => item1 === item2,
  /**
   * Returns true if the item1.{attributeName} fully equals item2.{attributeName}(===).
   * @param attributeName
   * @returns {function(*,*): boolean}
   */
  attributeEquals: attributeName => (item1, item2) => commonItemMatchers.equals(
    item1[attributeName],
    item2[attributeName]
  )
}

export const commonUpdateStrategies = {
  /**
   * New item completely overrides updated item.
   * @param updatedItem
   * @param newItem
   */
  overwrite: (updatedItem, newItem) => newItem,
  /**
   * New item shallow-merged with updated item.
   * @param updatedItem {object}
   * @param newItem {object}
   * @returns {object}
   */
  shallowMergeObject: (updatedItem, newItem) => ({
    ...updatedItem,
    ...newItem
  })
}

export const commonHandlers = {
  /**
   * Creates a reducer function that updates the value of specified store attributes with the values sent through the
   * action.data member.
   * @param valueNames {[string] | string} the name of the store attribute(s) updated.
   * @param valueNamesInAction {object} if some store attributes map to different action action.data
   *                                    attributes, the custom mapping can be specified through a key-value pair inside
   *                                    this parameter, where the key is the name of the attribute inside the store and
   *                                    the value is the name of the attribute inside action.data.
   * @returns {function(*, *): *}
   */
  setValue: (valueNames, valueNamesInAction = {}) => (state, action) => Array.isArray(valueNames)
    ? valueNames.reduce(
        (newState, valueName) => {
          const valueNameInAction = (valueNamesInAction && valueNamesInAction[valueName]) || valueName
          newState[valueName] = action.data[valueNameInAction]
          return newState
        },
        { ...state }
      )
    : ({
        ...state,
        [valueNames]: action.data[(valueNamesInAction && valueNamesInAction[valueNames]) || valueNames]
      }),
  /**
   * Creates a reducer function that updates item(s) inside an array of items with the specified update strategy.
   * @param memberNameInState {string} the attribute name of the member inside the state containing the items.
   * @param itemMemberNameInAction {string} the attribute name of the member inside action.data. Matching items will be updated
   * with this value.
   * @param matchItems {function(*, *): boolean} should return true for the item(s) that should be updated.
   * First argument is the current item inside the array, second argument is the item from the action.
   * Default value: items are matched by id.
   * @param updateStrategy {function(*, *): *} should implement the update process of matching items.
   * First argument is the updated item, second argument is the item from the action.
   * Default value: the updated item(s) and the item from the action are considered objects, and they are shallow merged.
   * @returns {function(*, *): *} the reducer function.
   */
  mergeItemIntoArrayMember: (
    memberNameInState,
    itemMemberNameInAction,
    matchItems = commonItemMatchers.attributeEquals('id'),
    updateStrategy = commonUpdateStrategies.shallowMergeObject
  ) => (state, action) => ({
    ...state,
    [memberNameInState]: state[memberNameInState].map(item => matchItems(item, action.data[itemMemberNameInAction])
      ? updateStrategy(item, action.data[itemMemberNameInAction])
      : item)
  }),
  resetState: initialState => () => ({
    ...initialState
  })
}
