import { commonHandlers, createReducer } from '../../utilities/store'
import GLOBAL_TYPES from '../types/Global'
import AGENTS_TYPES from '../types/Agents'

const initialState = {
  currentAgent: null
}

const handlers = {
  [AGENTS_TYPES.SET_CURRENT_AGENT]: commonHandlers.setValue('currentAgent'),
  [GLOBAL_TYPES.RESET_STORE]: commonHandlers.resetState(initialState)
}

export default createReducer(
  initialState,
  handlers
)
