import React, { useCallback, useEffect, useMemo, useState } from 'react'
import GridContainer from '../../../@jumbo/components/GridContainer'
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer'
import IntlMessages from '../../../@jumbo/utils/IntlMessages'
import Grid from '@material-ui/core/Grid'
import { selectAgentProcessesList } from '../../../redux/selectors/AgentProcesses'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAgentProcesses } from '../../../redux/actions/AgentProcesses'
import { setCurrentAgent } from '../../../redux/actions/Agents'
import { setCurrentProcess } from '../../../redux/actions/Processes'
import EditAgentDialog from '../../commonComponents/EditAgentDialog'
import EditProcessDialog from '../../commonComponents/EditProcessDialog'
import { selectAgentJailsList } from '../../../redux/selectors/AgentJails'
import { requestJails } from '../../../redux/actions/AgentJails'
import ServerItem from './components/ServerItem'
import { useNavigate } from 'react-router-dom'
import PATHS from '../../paths'

const breadcrumbs = [
  { label: <IntlMessages id='sidebar.main' />, link: '/' },
  { label: <IntlMessages id='pages.dashboard' />, isActive: true }
]

const Dashboard = () => {
  const dispatch = useDispatch()
  const agentProcesses = useSelector(selectAgentProcessesList)
  const agentJails = useSelector(selectAgentJailsList)
  const [isAgentEditDialogOpen, setIsAgentEditDialogOpen] = useState(false)
  const [isProcessEditDialogOpen, setIsProcessEditDialogOpen] = useState(false)
  const navigate = useNavigate()

  const mergedServerAgentsProcessesJails = useMemo(
    () => agentProcesses
      .reduce((mergedServerAgentsProcessesJails, agent) => {
        let existingServer = mergedServerAgentsProcessesJails.find(server => server.id === agent.serverId)
        if (!existingServer) {
          existingServer = { id: agent.serverId, Processes: [], Jails: [] }
          mergedServerAgentsProcessesJails.push(existingServer)
        }
        // server processes
        existingServer.Processes = existingServer.Processes.concat(
          agent.Processes.map(process => ({
            ...process,
            Agent: agent
          }))
        )
        // server jails
        existingServer.Jails = existingServer.Jails.concat(
          agentJails.find(agentJail => agentJail.agentId === agent.id)?.jails || []
        )

        return mergedServerAgentsProcessesJails
      }, []),
    [agentProcesses, agentJails]
  )

  const handleEditAgent = useCallback(agent => {
    dispatch(setCurrentAgent(agent))
    setIsAgentEditDialogOpen(true)
  }, [setIsAgentEditDialogOpen, dispatch])

  const handleEditAgentDialogClose = useCallback(() => {
    dispatch(setCurrentAgent(null))
    setIsAgentEditDialogOpen(false)
  }, [setIsAgentEditDialogOpen, dispatch])

  const handleEditProcess = useCallback(process => {
    dispatch(setCurrentProcess(process))
    setIsProcessEditDialogOpen(true)
  }, [setIsProcessEditDialogOpen, dispatch])

  const handleEditProcessDialogClose = useCallback(() => {
    dispatch(setCurrentProcess(null))
    setIsProcessEditDialogOpen(false)
  }, [setIsProcessEditDialogOpen, dispatch])

  const handleProcessStatsClicked = useCallback(process => {
    dispatch(setCurrentProcess(process))
    navigate(`/${PATHS.PROCESS_STATISTICS.MAIN.replace(':processId', process.id)}`)
  }, [dispatch, navigate])

  const handleActivityClicked = useCallback(process => {
    dispatch(setCurrentProcess(process))
    navigate(`/${PATHS.PROCESS_ACTIVITY.MAIN.replace(':processId', process.id)}`)
  }, [dispatch, navigate])

  const renderServer = useCallback(server => (
    <ServerItem
      key={server.id}
      server={server}
      onAgentEdit={handleEditAgent}
      onProcessEdit={handleEditProcess}
      onProcessStatsClicked={handleProcessStatsClicked}
      onActivityClicked={handleActivityClicked}
    />
  ), [handleEditAgent, handleEditProcess, handleProcessStatsClicked, handleActivityClicked])

  useEffect(() => {
    dispatch(fetchAgentProcesses())
    dispatch(requestJails())
  }, [dispatch])

  return (
    <PageContainer heading={<IntlMessages id='pages.dashboard' />} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <div style={{ marginTop: 24 }}>
            {mergedServerAgentsProcessesJails.map(renderServer)}
          </div>
        </Grid>
      </GridContainer>
      <EditAgentDialog isOpen={isAgentEditDialogOpen} onClose={handleEditAgentDialogClose} />
      <EditProcessDialog isOpen={isProcessEditDialogOpen} onClose={handleEditProcessDialogClose} />
    </PageContainer>
  )
}

export default Dashboard
