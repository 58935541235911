const PROCESSES_TYPES = {
  START_PROCESS: '[processes] startProcess',
  STOP_PROCESS: '[processes] stopProcess',
  RESTART_PROCESS: '[processes] restartProcess',
  UPDATE_LOCAL_PROCESS: '[processes] updateLocalProcess',
  SET_CURRENT_PROCESS: '[processes] setCurrentProcess',
  UPDATE_PROCESS: '[processes] updateProcess'
}

export default PROCESSES_TYPES
