import { commonHandlers, createReducer } from '../../utilities/store'
import USERS_TYPES from '../types/Users'
import GLOBAL_TYPES from '../types/Global'

const initialState = {
  list: [],
  currentUser: null
}

const handlers = {
  [USERS_TYPES.SET]: commonHandlers.setValue('list'),
  [USERS_TYPES.UPDATE_LOCAL_USER]: commonHandlers.mergeItemIntoArrayMember('list', 'user'),
  [USERS_TYPES.DELETE_LOCAL_USER]: (state, action) => ({
    ...state,
    list: state.list.filter(stateUser => stateUser.id !== action.data.user.id)
  }),
  [USERS_TYPES.SET_CURRENT_USER]: commonHandlers.setValue('currentUser'),
  [GLOBAL_TYPES.RESET_STORE]: commonHandlers.resetState(initialState)
}

export default createReducer(
  initialState,
  handlers
)
