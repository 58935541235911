import { all, fork } from 'redux-saga/effects'
import AuthSaga from './Auth'
import UsersSaga from './Users'
import AgentProcessesSaga from './AgentProcesses'
import AgentsSaga from './Agents'
import ProcessesSaga from './Processes'
import AgentJailsSaga from './AgentJails'
import AgentActivitiesSaga from './AgentActivities'
import ExecutedCommandsSaga from './ExecutedCommands'

export default function * rootSaga () {
  yield all([
    fork(AuthSaga),
    fork(UsersSaga),
    fork(AgentProcessesSaga),
    fork(AgentsSaga),
    fork(ProcessesSaga),
    fork(AgentJailsSaga),
    fork(AgentActivitiesSaga),
    fork(ExecutedCommandsSaga)
  ])
}
