export const ENVIRONMENT_TYPES = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production'
}

export const ENV = ENVIRONMENT_TYPES.DEVELOPMENT // TODO: set process.env.NODE_ENV when dotenv is configured

export const LOG_LEVEL_NAMES = {
  DEBUG: 'debug',
  INFO: 'info',
  WARNING: 'warn',
  ERROR: 'error'
}

export const AUTH_TOKEN_LOCAL_STORAGE_KEY = 'token'
