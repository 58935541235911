import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import GridContainer from '../../../@jumbo/components/GridContainer'
import Grid from '@material-ui/core/Grid'
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput'
import Button from '@material-ui/core/Button'
import { emailNotValid, requiredMessage } from '../../../@jumbo/constants/ErrorMessages'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { isValidEmail } from '../../../@jumbo/utils/commonHelper'
import AppCheckbox from '../../../@jumbo/components/Common/formElements/AppCheckBox'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative'
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  }
}))

const EditUserDialog = ({ open, onCloseDialog, user, onUserSave }) => {
  const classes = useStyles()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [notificationsEnabled, setNotificationsEnabled] = useState(false)

  const [firstNameError, setFirstNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  useEffect(() => {
    if (user) {
      const { firstName, lastName, mail, notificationsEnabled } = user
      setFirstName(firstName)
      setLastName(lastName)
      setEmail(mail)
      setNotificationsEnabled(notificationsEnabled)
    }
  }, [user])

  const onSubmitClick = () => {
    if (!firstName) {
      setFirstNameError(requiredMessage)
    } else if (!email) {
      setEmailError(requiredMessage)
    } else if (!isValidEmail(email)) {
      setEmailError(emailNotValid)
    } else if (!user && !password) { // password is mandatory on create
      setPasswordError(requiredMessage)
    } else {
      handleUserSave()
    }
  }

  const handleUserSave = () => {
    const userDetail = {
      firstName,
      lastName,
      mail: email,
      password,
      notificationsEnabled
    }

    onUserSave && onUserSave(userDetail)
  }

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle
        className={classes.dialogTitleRoot}
      >
        {user ? 'Edit User Details' : 'Create New User'}
      </DialogTitle>
      <DialogContent dividers>
        <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }} alignItems='center' mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                fullWidth
                variant='outlined'
                label='First name'
                value={firstName}
                onChange={e => {
                  setFirstName(e.target.value)
                  setFirstNameError('')
                }}
                helperText={firstNameError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                fullWidth
                variant='outlined'
                label='Last name'
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            label='Email Address'
            value={email}
            onChange={e => {
              setEmail(e.target.value)
              setEmailError('')
            }}
            helperText={emailError}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            label='Password'
            value={password}
            onChange={e => {
              setPassword(e.target.value)
              setPasswordError('')
            }}
            helperText={passwordError}
            type='password'
          />
        </Box>
        <Box mb={{ xs: 12 }}>
          <AppCheckbox
            fullWidth
            label={(
              <FormattedMessage id='EditUserDialog.notificationsEnabled' />
            )}
            checked={notificationsEnabled}
            onChange={e => setNotificationsEnabled(e.target.checked)}
          />
        </Box>
        <Box display='flex' justifyContent='flex-end' mb={4}>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button variant='contained' color='primary' onClick={onSubmitClick}>
              Save
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default EditUserDialog

EditUserDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
  user: PropTypes.object.isRequired,
  onUserSave: PropTypes.func
}
