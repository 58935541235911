import {
  SEND_FORGET_PASSWORD_EMAIL,
  UPDATE_AUTH_USER,
  UPDATE_EDIT_PROFILE_DIALOG_OPEN,
  UPDATE_LOAD_USER
} from '../../@jumbo/constants/ActionTypes'
import GLOBAL_TYPES from '../types/Global'
import AUTH_TYPES from '../types/Auth'

const initialState = {
  authUser: null,
  loadUser: false,
  send_forget_password_email: false,
  editProfileDialogOpen: false,
  twoFactorAuth: {
    controlCode: null,
    expiresInMinutes: null,
    sub: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        authUser: action.payload,
        loadUser: true
      }
    }
    case UPDATE_LOAD_USER: {
      return {
        ...state,
        loadUser: action.payload
      }
    }
    case SEND_FORGET_PASSWORD_EMAIL: {
      return {
        ...state,
        send_forget_password_email: action.payload
      }
    }
    case UPDATE_EDIT_PROFILE_DIALOG_OPEN: {
      return {
        ...state,
        editProfileDialogOpen: action.payload
      }
    }
    case AUTH_TYPES.SET_TWO_FACTOR_AUTHENTICATION_INFO: {
      return {
        ...state,
        twoFactorAuth: action.data.twoFactorAuth
      }
    }
    case GLOBAL_TYPES.RESET_STORE: {
      return {
        ...initialState,
        loadUser: true
      }
    }
    default:
      return state
  }
}
