import React from 'react'
import Dashboard from '@material-ui/icons/Dashboard'
import Group from '@material-ui/icons/Group'
import PostAdd from '@material-ui/icons/PostAdd'
import Mouse from '@material-ui/icons/Mouse'
import MultilineChartIcon from '@material-ui/icons/MultilineChart'
import IntlMessages from '../../../utils/IntlMessages'
import PATHS from '../../../../routes/paths'

export const sidebarNavs = [
  {
    name: <IntlMessages id='sidebar.main' />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id='pages.dashboard' />,
        type: 'item',
        icon: <Dashboard />,
        link: PATHS.DASHBOARD
      },
      {
        name: <IntlMessages id='pages.processActivities' />,
        type: 'item',
        icon: <MultilineChartIcon />,
        link: PATHS.PROCESS_ACTIVITIES.MAIN
      },
      {
        name: <IntlMessages id='pages.users' />,
        type: 'item',
        icon: <Group />,
        link: PATHS.USERS.MAIN
      },
      {
        name: <IntlMessages id='pages.executedCommands' />,
        type: 'item',
        icon: <Mouse />,
        link: PATHS.EXECUTED_COMMANDS.MAIN
      }
    ]
  }
]

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id='sidebar.main' />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id='pages.samplePage' />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page'
      }
    ]
  }
]

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id='sidebar.main' />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id='pages.samplePage' />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page'
      }
    ]
  }
]
