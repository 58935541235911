import { all, fork, put, takeLatest, select } from 'redux-saga/effects'
import { fetchStart, fetchSuccess } from '../actions'
import { handleRemoteApiError } from '../../utilities/responseHandling'
import USERS_TYPES from '../types/Users'
import { deleteLocalUser, setUsers, updateLocalUser } from '../actions/Users'
import UserService from '../../services/User'
import WS_EVENTS_TYPES from '../types/WSEvents'
import { eventSubscriptionToSaga, SagaUpdateChannelStore } from '../../utilities/store/sagas'
import SUCCESS_CODES from '../../constants/successCodes'
import { selectUsers } from '../selectors/Users'

function * fetchUsers () {
  try {
    yield put(fetchStart('users'))
    const { items: users } = yield UserService.FindMany() // TODO: send pagination-, sorting- & filtering params
    // TODO: read data with general list data reader: here(in the saga layer) or in the service layer?

    yield put(setUsers(users))
    yield put(fetchSuccess(undefined, 'users'))
  } catch (e) {
    yield handleRemoteApiError(e, 'users', 'Failed to fetch users')
  }
}

function * deleteUser (action) {
  try {
    yield put(fetchStart('deleteUser'))
    const { data: { user: { id } } } = action
    yield UserService.Delete(id)
    yield put(fetchSuccess(SUCCESS_CODES.SUCCESS, 'deleteUser'))
  } catch (e) {
    yield handleRemoteApiError(e, 'deleteUser', 'Failed to delete user')
  }
}

function * createUser (action) {
  try {
    yield put(fetchStart('createUser'))
    const { data: { user, callback } } = action
    yield UserService.Create(user)
    yield put(fetchSuccess(SUCCESS_CODES.SUCCESS, 'createUser'))
    callback && callback()
  } catch (e) {
    yield handleRemoteApiError(e, 'createUser', 'Failed to create user')
  }
}

function * updateUser (action) {
  try {
    yield put(fetchStart('updateUser'))
    const { data: { userId, user, callback } } = action
    yield UserService.Update(userId, user)
    yield put(fetchSuccess(SUCCESS_CODES.SUCCESS, 'updateUser'))
    callback && callback()
  } catch (e) {
    yield handleRemoteApiError(e, 'updateUser', 'Failed to update user')
  }
}

function * updateProfile (action) {
  try {
    yield put(fetchStart('updateProfile'))
    const { data: { profile, callback } } = action
    yield UserService.UpdateProfile(profile)
    yield put(fetchSuccess(SUCCESS_CODES.SUCCESS, 'updateProfile'))
    callback && callback()
  } catch (e) {
    yield handleRemoteApiError(e, 'updateProfile', 'Failed to update profile')
  }
}

function * handleUserUpdate (data) {
  yield put(updateLocalUser(data))
}

function * handleUserDelete (data) {
  yield put(deleteLocalUser(data))
}

function * handleUserCreated (data) {
  const { list } = yield select(selectUsers)
  const users = [...list, data] // add newly created user to the list

  yield put(setUsers(users))
}

const updateChannelStore = new SagaUpdateChannelStore()

function * subscribeToWSEvents () {
  yield all([
    fork(eventSubscriptionToSaga, UserService.OnUserUpdated, UserService.OffUserUpdated, handleUserUpdate, updateChannelStore.AddUpdateChannel),
    fork(eventSubscriptionToSaga, UserService.OnUserDeleted, UserService.OffUserDeleted, handleUserDelete, updateChannelStore.AddUpdateChannel),
    fork(eventSubscriptionToSaga, UserService.OnUserCreated, UserService.OffUserCreated, handleUserCreated, updateChannelStore.AddUpdateChannel)
  ])
}

function * unsubscribeFromWSEvents () {
  yield updateChannelStore.CloseUpdateChannels()
}

function * UsersSaga () {
  yield takeLatest(USERS_TYPES.FETCH, fetchUsers)
  yield takeLatest(USERS_TYPES.DELETE_USER, deleteUser)
  yield takeLatest(USERS_TYPES.CREATE_USER, createUser)
  yield takeLatest(USERS_TYPES.UPDATE_USER, updateUser)
  yield takeLatest(USERS_TYPES.UPDATE_PROFILE, updateProfile)
  yield takeLatest(WS_EVENTS_TYPES.SUBSCRIBE, subscribeToWSEvents)
  yield takeLatest(WS_EVENTS_TYPES.UNSUBSCRIBE, unsubscribeFromWSEvents)
}

export default UsersSaga
