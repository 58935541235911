import React from 'react'
import PropTypes from 'prop-types'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'

const headCells = [
  {
    id: 'actionName',
    numeric: false,
    label: 'Action name'
  },
  {
    id: 'details',
    numeric: false,
    disablePadding: false,
    label: 'Details'
  },
  {
    id: 'agentName',
    numeric: false,
    disablePadding: false,
    label: 'Executed on'
  },
  { id: 'userName', numeric: false, disablePadding: false, label: 'Executed by' },
  { id: 'executionDate', numeric: false, disablePadding: false, label: 'Execution date' }
]

function ExecutedCommandTableHead ({ classes, order, orderBy, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id
                ? (
                  <span
                    className={classes.visuallyHidden}
                  >{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                  )
                : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

ExecutedCommandTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
}

export default React.memo(ExecutedCommandTableHead)
