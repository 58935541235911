// import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions'
import { authorize, login, logout, resetPassword } from '../../../redux/actions/Auth'
import React from 'react'

const WSSManagementAuth = {
  /* onRegister: ({ name, email, password }) => {
     return dispatch => {
       dispatch(fetchStart())

       setTimeout(() => {
         dispatch(fetchSuccess())
         const user = { name: name, email: email, password: password }
         localStorage.setItem('user', JSON.stringify(user))
         dispatch(setAuthUser(user))
       }, 300)
     }
   }, */

  onLogin: ({ email, password }) => dispatch => {
    dispatch(
      login(email, password)
    )
  },
  onLogout: () => dispatch => {
    dispatch(
      logout()
    )
  },
  getAuthUser: () => dispatch => {
    dispatch(
      authorize()
    )
  },
  onForgotPassword: ({ email }) => dispatch => {
    dispatch(
      resetPassword(email)
    )
  },

  getSocialMediaIcons: () => {
    return <> </>
  }
}

export default WSSManagementAuth
