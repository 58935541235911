export const API_CONFIG = {
  HTTP: {
    URL: process.env.REACT_APP_HTTP_API_URL
  },
  WS: {
    URL: process.env.REACT_APP_WS_API_URL,
    NAMESPACE: process.env.REACT_APP_WS_API_NAMESPACE,
    TOKEN_QUERY_PARAM_NAME: process.env.REACT_APP_WS_API_TOKEN_QUERY_PARAM_NAME
  }
}

export const HTTP_API_ENDPOINTS = {
  LOGIN: '/login',
  VERIFY_AUTH_CODE: '/verify-auth-code',
  LOGOUT: '/logout',
  GET_AUTH_USER: '/get-auth-user',
  RESET_PASSWORD: '/reset-password'
}

export const SOCKET_IO_EVENTS = {
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',
  CONNECT_ERROR: 'connect_error'
}

export const WS_API_EVENTS = {
  OUTGOING: {
    // user specific events
    USER_CREATE: '[web] user::create',
    USER_UPDATE: '[web] user::update',
    USER_DELETE: '[web] user::delete',
    USER_FIND_MANY: '[web] user::findMany',
    // profile specific events
    PROFILE_UPDATE: '[web] profile::update',
    // agent specific events
    AGENT_CREATE: '[web] agent::create',
    AGENT_UPDATE: '[web] agent::update',
    AGENT_DELETE: '[web] agent::delete',
    AGENT_FIND_MANY: '[web] agent::findMany',
    // process specific events
    PROCESS_START: '[web] process::start',
    PROCESS_UPDATE: '[web] process::update',
    PROCESS_STOP: '[web] process::stop',
    PROCESS_RESTART: '[web] process::restart',
    // process-status specific events
    PROCESS_STATUS_FIND_MANY: '[web] processStatus::findMany',
    // aggregated reader events
    AGENT_PROCESS_FIND_MANY: '[web] agentProcess::findMany',
    // ip management specific events
    IP_UNBAN: '[web] ip::unban',
    IP_REQUEST_ALL: '[web] ip::requestAll',
    // activity specific events
    ACTIVITY_REQUEST: '[web] activity::request',
    ACTIVITY_FIND_MANY: '[web] activity::findMany',
    // command specific events
    EXECUTED_COMMAND_FIND_MANY: '[web] executedCommand::findMany'
  },
  INCOMING: {
    // user specific events
    USER_CREATED: '[web] user::created',
    USER_UPDATED: '[web] user::updated',
    USER_DELETED: '[web] user::deleted',
    // agent specific events
    AGENT_CREATED: '[web] agent::created',
    AGENT_UPDATED: '[web] agent::updated',
    AGENT_DELETED: '[web] agent::deleted',
    // process specific events
    PROCESS_CREATED: '[web] process::created',
    PROCESS_UPDATED: '[web] process::updated',
    PROCESS_DELETED: '[web] process::deleted',
    // ip management specific events
    IP_UPDATED_MANY: '[web] ip::updatedMany',
    // activity specific events
    ACTIVITY_UPDATED: '[web] activity::updated'
  }
}

export const WS_EVENT_RESPONSE_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error'
}
