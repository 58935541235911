import axios from 'axios'
import { API_CONFIG } from '../constants/api'

const httpClient = axios.create({
  baseURL: API_CONFIG.HTTP.URL
})

let authInterceptor = null

export const authorizeHttpClient = authToken => {
  authInterceptor = httpClient.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${authToken}`
    return config
  })
}

export const unAuthorizeHttpClient = () => {
  typeof authInterceptor !== 'undefined' &&
  authInterceptor !== null &&
  httpClient.interceptors.request.eject(authInterceptor)
}

export default httpClient
