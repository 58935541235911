const PATHS = {
  ROOT: '/',
  AUTH: {
    LOGIN: 'signin',
    FORGOT_PASSWORD: 'forgot-password'
  },
  DASHBOARD: 'dashboard',
  USERS: {
    MAIN: 'users'
  },
  PROCESS_STATISTICS: {
    MAIN: 'process/:processId/statistics'
  },
  EXECUTED_COMMANDS: {
    MAIN: 'executed-commands'
  },
  PROCESS_ACTIVITY: {
    MAIN: 'process/:processId/activity',
    STATISTICS: 'process/activity-statistics'
  },
  PROCESS_ACTIVITIES: {
    MAIN: 'process-activities'
  }
}

export default PATHS
