import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import 'react-perfect-scrollbar/dist/css/styles.css'
import store from './redux/store'
import AppWrapper from './@jumbo/components/AppWrapper'
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider'
import Routes from './routes'

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <AppContextProvider>
        <AppWrapper>
            <Routes />
        </AppWrapper>
      </AppContextProvider>
    </BrowserRouter>
  </Provider>
)

export default App
