import React, { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import PATHS from '../paths'
import EditProfileDialog from '../commonComponents/EditPorfileDialog'
import { updateEditProfileDialogOpen } from '../../redux/actions/Auth'
import { useDispatch, useSelector } from 'react-redux'
import { selectEditProfileDialogOpen } from '../../redux/selectors/Auth'
import Dashboard from './Dashboard'
import Users from './Users'
import ExecutedCommands from './ExecutedCommands'
import ProcessStatistics from './ProcessStatistics'
import Error404 from './404'
import ProcessActivity from './ProcessActivity'
import ProcessActivities from './ProcessActivities'
import ProcessActivityStatistics from './ProcessActivityStatistics'

// prevent rerendering all the routes when the popup opens
const ProfileDialog = React.memo(
  () => {
    const isEditProfileDialogOpen = useSelector(selectEditProfileDialogOpen)
    const dispatch = useDispatch()
    const handleProfileDialogClose = () => dispatch(updateEditProfileDialogOpen(false))

    return (
      <EditProfileDialog
        open={isEditProfileDialogOpen}
        onCloseDialog={handleProfileDialogClose}
      />
    )
  }
)

const Admin = () => (
  <>
    <ProfileDialog />
    <Routes>
      <Route path={PATHS.DASHBOARD} element={<Dashboard />} />
      <Route path={PATHS.USERS.MAIN} element={<Users />} />
      <Route path={PATHS.EXECUTED_COMMANDS.MAIN} element={<ExecutedCommands />} />
      <Route path={PATHS.PROCESS_STATISTICS.MAIN} element={<ProcessStatistics />} />
      <Route path={PATHS.PROCESS_ACTIVITY.MAIN} element={<ProcessActivity />} />
      <Route path={PATHS.PROCESS_ACTIVITY.STATISTICS} element={<ProcessActivityStatistics />} />
      <Route path={PATHS.PROCESS_ACTIVITIES.MAIN} element={<ProcessActivities />} />
      <Route element={<Error404 />} />
    </Routes>
  </>
)

export default Admin
