import { all, fork, put, takeLatest } from 'redux-saga/effects'
import { fetchStart, fetchSuccess } from '../actions'
import { handleRemoteApiError } from '../../utilities/responseHandling'
import WS_EVENTS_TYPES from '../types/WSEvents'
import { eventSubscriptionToSaga, SagaUpdateChannelStore } from '../../utilities/store/sagas'
import PROCESSES_TYPES from '../types/Processes'
import ProcessService from '../../services/Process'
import { setCurrentProcess, updateLocalProcess } from '../actions/Processes'
import SUCCESS_CODES from '../../constants/successCodes'

function * startProcess (action) {
  try {
    yield put(fetchStart('startProcess'))
    const { data: { processId } } = action
    yield ProcessService.Start(processId)
    yield put(fetchSuccess(SUCCESS_CODES.SUCCESS, 'startProcess'))
  } catch (e) {
    yield handleRemoteApiError(e, 'startProcess', 'Failed to start process')
  }
}

function * stopProcess (action) {
  try {
    yield put(fetchStart('stopProcess'))
    const { data: { processId } } = action
    yield ProcessService.Stop(processId)
    yield put(fetchSuccess(SUCCESS_CODES.SUCCESS, 'stopProcess'))
  } catch (e) {
    yield handleRemoteApiError(e, 'stopProcess', 'Failed to stop process')
  }
}

function * restartProcess (action) {
  try {
    yield put(fetchStart('restartProcess'))
    const { data: { processId } } = action
    yield ProcessService.Restart(processId)
    yield put(fetchSuccess(SUCCESS_CODES.SUCCESS, 'restartProcess'))
  } catch (e) {
    yield handleRemoteApiError(e, 'restartProcess', 'Failed to restart process')
  }
}

function * updateProcess (action) {
  try {
    yield put(fetchStart('updateProcess'))
    const { data: { process, callback } } = action
    yield ProcessService.Update(process.id, process)
    yield put(fetchSuccess(SUCCESS_CODES.SUCCESS, 'updateProcess'))
    yield put(setCurrentProcess(null))
    callback && callback()
  } catch (e) {
    yield handleRemoteApiError(e, 'updateProcess', 'Failed to update process')
  }
}

function * handleProcessUpdate (data) {
  yield put(updateLocalProcess(data))
}

const updateChannelStore = new SagaUpdateChannelStore()

function * subscribeToWSEvents () {
  yield all([
    fork(eventSubscriptionToSaga, ProcessService.OnProcessUpdated, ProcessService.OffProcessUpdated, handleProcessUpdate, updateChannelStore.AddUpdateChannel)
  ])
}

function * unsubscribeFromWSEvents () {
  yield updateChannelStore.CloseUpdateChannels()
}

function * ProcessesSaga () {
  yield takeLatest(PROCESSES_TYPES.START_PROCESS, startProcess)
  yield takeLatest(PROCESSES_TYPES.STOP_PROCESS, stopProcess)
  yield takeLatest(PROCESSES_TYPES.RESTART_PROCESS, restartProcess)
  yield takeLatest(PROCESSES_TYPES.UPDATE_PROCESS, updateProcess)
  yield takeLatest(WS_EVENTS_TYPES.SUBSCRIBE, subscribeToWSEvents)
  yield takeLatest(WS_EVENTS_TYPES.UNSUBSCRIBE, unsubscribeFromWSEvents)
}

export default ProcessesSaga
