class FilterParameter {

  static FILTER_TYPES = {
    EXACT_MATCH: 'EXACT_MATCH',
    CONTAINS_STRING: 'CONTAINS_STRING',
    GREATER_THAN_OR_EQUAL: 'GREATER_THAN_OR_EQUAL',
    LESS_THAN_OR_EQUAL: 'LESS_THAN_OR_EQUAL'
  }

  constructor (attributeNames, filterValue, filterType) {
    const allowedFilterTypes = Object.values(FilterParameter.FILTER_TYPES)

    if (!allowedFilterTypes.includes(filterType)) {
      throw new Error('[FilterParameter] Invalid filter type: ', filterType)
    }

    this.attributeNames = attributeNames || []
    this.filterValue = filterValue
    this.filterType = filterType
  }

  static FromPlainObject ({
    attributeNames,
    filterValue,
    filterType
  }) {
    return new FilterParameter(attributeNames, filterValue, filterType)
  }

  static ParsePlainFilterParametersIntoFilterParameterCollection (plainFilterParameters) {
    return plainFilterParameters
      .map(FilterParameter.FromPlainObject)
  }

  toPlainObject () {
    return {
      attributeNames: this.attributeNames,
      filterValue: this.filterValue,
      filterType: this.filterType
    }
  }

  static FilterParameterCollectionToPlainFilterParameterCollection (filterParameterCollection) {
    return filterParameterCollection.map(filterParameter => filterParameter.toPlainObject())
  }
}

export default FilterParameter
