import React, { useContext } from 'react'
import FooterLogo from './FooterLogo'
import { Box, makeStyles } from '@material-ui/core'
import Hidden from '@material-ui/core/Hidden'
import AppContext from '../../contextProvider/AppContextProvider/AppContext'
import { THEME_TYPES } from '../../../constants/ThemeOptions'
import CmtImage from '../../../../@coremat/CmtImage'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btnRoot: {
    [theme.breakpoints.down('xs')]: {
      padding: '6px 12px',
      fontSize: 11
    }
  },
  logo: {
    borderRadius: 5,
    padding: 2.5,
    width: 20,
    backgroundColor: theme.palette.text.disabled
  }
}))

const Footer = props => {
  const classes = useStyles()
  const { themeType } = useContext(AppContext)
  const date = new Date()

  return (
    <Box className={classes.root} {...props}>
      <Box display='flex' alignItems='center'>
        <Hidden xsDown>
          <CmtImage src='/logo198.png' className={classes.logo} />
        </Hidden>
        <Box fontSize={{ xs: 12, sm: 14 }} component='p' color='text.disabled' ml={2}>
          World Safe System © {date.getFullYear()}
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
