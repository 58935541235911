import { commonHandlers, createReducer } from '../../utilities/store'
import GLOBAL_TYPES from '../types/Global'
import AGENT_ACTIVITIES_TYPES from '../types/AgentActivities'

const initialState = {
  list: []
}

const handlers = {
  [AGENT_ACTIVITIES_TYPES.UPSERT]: (state, action) => ({
    ...state,
    list: state.list.find(({ agentId }) => agentId === action.data.activity.agentId)
      ? state.list.map(
          agentActivity => agentActivity.agentId === action.data.activity.agentId // update existing activity
            ? action.data.activity
            : agentActivity
        )
      : [...state.list, action.data.activity] // new agent activity item
  }),
  [GLOBAL_TYPES.RESET_STORE]: commonHandlers.resetState(initialState)
}

export default createReducer(
  initialState,
  handlers
)
