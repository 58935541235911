import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import TableRow from '@material-ui/core/TableRow'
import { timeFromNow } from '../../../../../@jumbo/utils/dateHelper'
import { Delete, Edit, MoreHoriz/*, Visibility */ } from '@material-ui/icons'
import CmtDropdownMenu from '../../../../../@coremat/CmtDropdownMenu'
import CmtAvatar from '../../../../../@coremat/CmtAvatar'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useViewModel from '../../../../../hooks/useViewModel'

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
  }
}))

const getUserActions = () => {
  const actions = [
    /* { action: 'view', label: 'View', icon: <Visibility /> }, */
    { action: 'edit', label: 'Edit', icon: <Edit /> },
    { action: 'delete', label: 'Delete', icon: <Delete /> }
  ]

  return actions
}

const userModelToUserListRowViewModel = ({
  id,
  mail,
  firstName,
  lastName,
  lastSuccessfulLogin
}) => ({
  id,
  email: mail,
  profile_pic: '', // TODO: add profile pic to user parameters server-side
  name: `${firstName} ${lastName}`,
  lastLoginAt: lastSuccessfulLogin
})

const UserListRow = ({ row, isSelected, onRowClick, onUserEdit, onUserDelete, onUserView }) => {
  const classes = useStyles()

  const userView = useViewModel(row, userModelToUserListRowViewModel)

  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row)
    } else if (menu.action === 'edit') {
      onUserEdit(row)
    } else if (menu.action === 'delete') {
      onUserDelete(row)
    }
  }

  const labelId = `enhanced-table-checkbox-${userView.id}`
  const isItemSelected = isSelected(userView.id)
  const userActions = getUserActions(row)

  return (
    <TableRow
      hover
      onClick={event => onRowClick(event, userView.id)}
      role='checkbox'
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={userView.id}
      selected={isItemSelected}
    >
      <TableCell padding='checkbox'>
        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
      </TableCell>
      <TableCell component='th' id={labelId} scope='row' padding='none'>
        <Box display='flex' alignItems='center'>
          <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar size={40} src={userView.profile_pic} alt={userView.name} />
          </Box>
          <div>
            <Typography className={classes.titleRoot} component='div' variant='h4'>
              {userView.name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>{userView.email}</TableCell>
      <TableCell>{timeFromNow(userView.lastLoginAt)}</TableCell>
      <TableCell align='center' onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<MoreHoriz />} />
      </TableCell>
    </TableRow>
  )
}

export default React.memo(UserListRow)
