import { all, fork, put, takeLatest } from 'redux-saga/effects'
import WS_EVENTS_TYPES from '../types/WSEvents'
import { eventSubscriptionToSaga, SagaUpdateChannelStore } from '../../utilities/store/sagas'
import { upsertAgentJails } from '../actions/AgentJails'
import IPManagementService from '../../services/IPManagement'
import AGENT_JAILS_TYPES from '../types/AgentJails'
import { fetchStart, fetchSuccess } from '../actions'
import SUCCESS_CODES from '../../constants/successCodes'
import { handleRemoteApiError } from '../../utilities/responseHandling'

function * requestJails () {
  try {
    yield put(fetchStart('requestJails'))
    yield IPManagementService.RequestJails()
    yield put(fetchSuccess(undefined, 'requestJails'))
  } catch (e) {
    yield handleRemoteApiError(e, 'requestJails', 'Failed to request jails')
  }
}

function * unbanIP (action) {
  try {
    yield put(fetchStart('unbanIP'))
    const { data: { agentId, jail, ip } } = action
    yield IPManagementService.UnbanIP(agentId, jail, ip)
    yield put(fetchSuccess(SUCCESS_CODES.SUCCESS, 'unbanIP'))
  } catch (e) {
    yield handleRemoteApiError(e, 'unbanIP', 'Failed to unban ip')
  }
}

function * handleIPUpdateMany (data) {
  yield put(upsertAgentJails(data))
}

const updateChannelStore = new SagaUpdateChannelStore()

function * subscribeToWSEvents () {
  yield all([
    fork(eventSubscriptionToSaga, IPManagementService.OnUpdatedMany, IPManagementService.OffUpdatedMany, handleIPUpdateMany, updateChannelStore.AddUpdateChannel)
  ])
}

function * unsubscribeFromWSEvents () {
  yield updateChannelStore.CloseUpdateChannels()
}

function * AgentJailsSaga () {
  yield takeLatest(AGENT_JAILS_TYPES.REQUEST, requestJails)
  yield takeLatest(AGENT_JAILS_TYPES.UNBAN_IP, unbanIP)
  yield takeLatest(WS_EVENTS_TYPES.SUBSCRIBE, subscribeToWSEvents)
  yield takeLatest(WS_EVENTS_TYPES.UNSUBSCRIBE, unsubscribeFromWSEvents)
}

export default AgentJailsSaga
