import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../@jumbo/constants/ActionTypes'
import GLOBAL_TYPES from '../types/Global'

const INIT_STATE = {
  initialURL: '/',
  error: '',
  message: '',
  loading: false,
  fetchIds: {}
}

const calculateHideLoadingProps = (state, fetchId) => {
  const fetchIds = Object.keys(state.fetchIds)
    .reduce(
      (fetchIds, id) => {
        // delete loading for @fetchId
        if (id !== fetchId) {
          fetchIds[id] = true
        }
        return fetchIds
      },
      {}
    )

  return {
    loading: !!Object.keys(fetchIds).length, // hide the loading indicator only when all fetches are completed
    fetchIds
  }
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state,
        error: '',
        message: '',
        loading: true,
        fetchIds: {
          ...state.fetchIds,
          [action.payload.fetchId]: true
        }
      }
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: '',
        message: action.payload.message,
        ...calculateHideLoadingProps(state, action.payload.fetchId)
      }
    }
    case FETCH_ERROR: {
      return {
        ...state,
        message: '',
        error: action.payload.error,
        ...calculateHideLoadingProps(state, action.payload.fetchId)
      }
    }
    case GLOBAL_TYPES.RESET_STORE: {
      return INIT_STATE
    }
    default:
      return state
  }
}
