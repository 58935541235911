import React from 'react'
import { FormattedMessage } from 'react-intl'

export const AGENT_CONNECTION_STATUS = {
  UNCONNECTED: {
    value: 0,
    label: ({ children }) => (
      <FormattedMessage
        id='AGENT_CONNECTION_STATUS.UNCONNECTED'
        defaultMessage='Unconnected'
      >
        {children}
      </FormattedMessage>
    )
  },
  CONNECTED: {
    value: 1,
    label: ({ children }) => (
      <FormattedMessage
        id='AGENT_CONNECTION_STATUS.CONNECTED'
        defaultMessage='Connected'
      >
        {children}
      </FormattedMessage>
    )
  }
}

export const PROCESS_RUN_STATUS = {
  STOPPED: {
    value: false,
    label: (
      <FormattedMessage
        id='PROCESS_RUN_STATUS.STOPPED'
        defaultMessage='Stopped'
      />
    )
  },
  RUNNING: {
    value: true,
    label: (
      <FormattedMessage
        id='PROCESS_RUN_STATUS.RUNNING'
        defaultMessage='Running'
      />
    )
  },
  UNKNOWN: {
    value: null,
    label: (
      <FormattedMessage
        id='PROCESS_RUN_STATUS.UNKNOWN'
        defaultMessage='Unknown'
      />
    )
  }
}

export const PROCESS_SOCKET_STATUS = {
  STOPPED: {
    value: false,
    label: (
      <FormattedMessage
        id='PROCESS_SOCKET_STATUS.STOPPED'
        defaultMessage='Stopped'
      />
    )
  },
  RUNNING: {
    value: true,
    label: (
      <FormattedMessage
        id='PROCESS_SOCKET_STATUS.RUNNING'
        defaultMessage='Running'
      />
    )
  },
  UNKNOWN: {
    value: null,
    label: (
      <FormattedMessage
        id='PROCESS_SOCKET_STATUS.UNKNOWN'
        defaultMessage='Unknown'
      />
    )
  }
}

export const COMMAND_NAMES = {
  UNBAN_IP: 'UNBAN_IP',
  STOP_PROCESS: 'STOP_PROCESS',
  START_PROCESS: 'START_PROCESS',
  RESTART_PROCESS: 'RESTART_PROCESS'
}

export const COMMAND_NAME = {
  [COMMAND_NAMES.UNBAN_IP]: {
    label: (
      <FormattedMessage
        id='COMMAND_NAME.UNBAN_IP'
        defaultMessage='Unban IP'
      />
    )
  },
  [COMMAND_NAMES.STOP_PROCESS]: {
    label: (
      <FormattedMessage
        id='COMMAND_NAME.STOP_PROCESS'
        defaultMessage='Stop process'
      />
    )
  },
  [COMMAND_NAMES.START_PROCESS]: {
    label: (
      <FormattedMessage
        id='COMMAND_NAME.START_PROCESS'
        defaultMessage='Start process'
      />
    )
  },
  [COMMAND_NAMES.RESTART_PROCESS]: {
    label: (
      <FormattedMessage
        id='COMMAND_NAME.RESTART_PROCESS'
        defaultMessage='Restart process'
      />
    )
  }
}
