import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import IntlMessages from '../../../utils/IntlMessages'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import { Box } from '@material-ui/core'
import { AuhMethods } from '../../../../services/auth'
import ContentLoader from '../../ContentLoader'
import { alpha, makeStyles } from '@material-ui/core/styles'
import CmtImage from '../../../../@coremat/CmtImage'
import Typography from '@material-ui/core/Typography'
/* import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox' */
import { CurrentAuthMethod } from '../../../constants/AppConstants'
import { Navigate, NavLink } from 'react-router-dom'
import AuthWrapper from './AuthWrapper'
import { selectAuth, selectTwoFactorAuthInfo } from '../../../../redux/selectors/Auth'
import { sendAuthCode } from '../../../../redux/actions/Auth'
import PATHS from '../../../../routes/paths'

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2
    }
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    }
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12
      }
    }
  },
  logo: {
    borderRadius: 5,
    padding: 5,
    width: 60,
    backgroundColor: theme.palette.common.logoBG
  }
}))
// variant = 'default', 'standard'
const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const twoFactorAuthInfo = useSelector(selectTwoFactorAuthInfo)
  const { authUser } = useSelector(selectAuth)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [sixDigitCode, setSixDigitCode] = useState('')
  const displayAuthCodeInput = twoFactorAuthInfo.controlCode && twoFactorAuthInfo.expiresInMinutes && twoFactorAuthInfo.sub
  const dispatch = useDispatch()
  const classes = useStyles({ variant })

  const handleLogin = () => {
    AuhMethods[method].onLogin({ email, password })(dispatch)
  }

  const handleSendAuthCode = () => dispatch(
    sendAuthCode(sixDigitCode)
  )

  if(authUser){
    return (
      <Navigate
        to={`/${PATHS.DASHBOARD}`}
        replace
      />
    )
  }

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default'
        ? (
          <Box className={classes.authThumb}>
            <CmtImage src='/images/auth/login-img.png' />
          </Box>
          )
        : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src='/logo198.png' className={classes.logo} />
        </Box>
        <Typography component='div' variant='h1' className={classes.titleRoot}>
          Login
        </Typography>
        <form>
          {!displayAuthCodeInput ? (
            <>
              <Box mb={2}>
                <TextField
                  label={<IntlMessages id='appModule.email' />}
                  fullWidth
                  onChange={event => setEmail(event.target.value)}
                  defaultValue={email}
                  margin='normal'
                  variant='outlined'
                  className={classes.textFieldRoot}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  type='password'
                  label={<IntlMessages id='appModule.password' />}
                  fullWidth
                  onChange={event => setPassword(event.target.value)}
                  defaultValue={password}
                  margin='normal'
                  variant='outlined'
                  className={classes.textFieldRoot}
                />
              </Box>
              <Box display='flex' alignItems='center' justifyContent='space-between' mb={5}>
                {/* <FormControlLabel
                        className={classes.formcontrolLabelRoot}
                        control={<Checkbox name='checkedA' />}
                        label='Remember me'
                      /> */}
                <Box component='p' fontSize={{ xs: 12, sm: 16 }}>
                  <NavLink to='/forgot-password'>
                    <IntlMessages id='appModule.forgotPassword' />
                  </NavLink>
                </Box>
              </Box>
            </>
          )
            : (
              <Box mb={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant='h3'
                  style={{ marginRight: 5 }}
                >
                  {twoFactorAuthInfo.controlCode}{' - '}
                </Typography>
                <TextField
                  type='number'
                  maxLength={6}
                  label={<IntlMessages id='appModule.sixDigitCode' />}
                  onChange={event => setSixDigitCode(event.target.value)}
                  value={sixDigitCode}
                  margin='normal'
                  variant='outlined'
                  className={classes.textFieldRoot}
                />
              </Box>
              )}

          <Box display='flex' alignItems='center' justifyContent='space-between' mb={5}>
            <Button onClick={!displayAuthCodeInput ? handleLogin : handleSendAuthCode} variant='contained' color='primary'>
              <IntlMessages id='appModule.signIn' />
            </Button>

            {/* <Box component='p' fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to='/signup'>
                <IntlMessages id='signIn.signUp' />
              </NavLink>
            </Box> */}
          </Box>
        </form>

        {/* dispatch(AuhMethods[method].getSocialMediaIcons()) */}

        <ContentLoader />
      </Box>
    </AuthWrapper>
  )
}

export default SignIn
