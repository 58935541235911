import webSocketClient from '../clients/webSocketClient'
import { WS_API_EVENTS } from '../constants/api'
import { withStandardWSResponseHandling } from '../utilities/responseHandling'

const Start = async processId =>
  webSocketClient.emitAsync(
    WS_API_EVENTS.OUTGOING.PROCESS_START,
    processId
  )

const Stop = async processId =>
  webSocketClient.emitAsync(
    WS_API_EVENTS.OUTGOING.PROCESS_STOP,
    processId
  )

const Restart = async processId =>
  webSocketClient.emitAsync(
    WS_API_EVENTS.OUTGOING.PROCESS_RESTART,
    processId
  )

const Update = async (processId, { name, socketMonitoringEnabled, publicUrl }) =>
  webSocketClient.emitAsync(
    WS_API_EVENTS.OUTGOING.PROCESS_UPDATE,
    processId,
    { name, socketMonitoringEnabled, publicUrl }
  )

const OnProcessUpdated = listener => webSocketClient.socket.on(WS_API_EVENTS.INCOMING.PROCESS_UPDATED, listener)

const OffProcessUpdated = listener => webSocketClient.socket.off(WS_API_EVENTS.INCOMING.PROCESS_UPDATED, listener)

const ProcessService = {
  Start: withStandardWSResponseHandling(Start),
  Stop: withStandardWSResponseHandling(Stop),
  Restart: withStandardWSResponseHandling(Restart),
  Update: withStandardWSResponseHandling(Update),
  OnProcessUpdated,
  OffProcessUpdated
}

export default ProcessService
