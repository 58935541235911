import { put, takeLatest } from 'redux-saga/effects'
import { fetchStart, fetchSuccess } from '../actions'
import { handleRemoteApiError } from '../../utilities/responseHandling'
import WS_EVENTS_TYPES from '../types/WSEvents'
import { SagaUpdateChannelStore } from '../../utilities/store/sagas'
import EXECUTED_COMMANDS_TYPES from '../types/ExecutedCommands'
import ExecutedCommandService from '../../services/ExecutedCommand'
import { setExecutedCommands } from '../actions/ExecutedCommands'

function * fetchExecutedCommands () {
  try {
    yield put(fetchStart('executedCommands'))
    const { items: executedCommands } = yield ExecutedCommandService.FindMany() // TODO: send pagination-, sorting- & filtering params
    // TODO: read data with general list data reader: here(in the saga layer) or in the service layer?

    yield put(setExecutedCommands(executedCommands))
    yield put(fetchSuccess(undefined, 'executedCommands'))
  } catch (e) {
    yield handleRemoteApiError(e, 'executedCommands', 'Failed to fetch executed commands')
  }
}

const updateChannelStore = new SagaUpdateChannelStore()

function * subscribeToWSEvents () {

}

function * unsubscribeFromWSEvents () {
  yield updateChannelStore.CloseUpdateChannels()
}

function * ExecutedCommandsSaga () {
  yield takeLatest(EXECUTED_COMMANDS_TYPES.FETCH, fetchExecutedCommands)
  yield takeLatest(WS_EVENTS_TYPES.SUBSCRIBE, subscribeToWSEvents)
  yield takeLatest(WS_EVENTS_TYPES.UNSUBSCRIBE, unsubscribeFromWSEvents)
}

export default ExecutedCommandsSaga
