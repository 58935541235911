import { PROCESS_SOCKET_STATUS } from '../../../../../constants/model'

export default theme => ({
  cardRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .Cmt-header-root': {
      paddingTop: 20
    }
  },
  userTitle: {
    marginBottom: 16,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 24
    }
  },
  progressBarRoot: {
    marginBottom: 16,
    '& .Cmt-fill-progress': {
      backgroundColor: `${theme.palette.success.main} !important`
    },
    '& .Cmt-label-container': {
      color: theme.palette.text.disabled,
      fontSize: 12,
      letterSpacing: 0.4
    }
  },
  status: ({ isRunning, isAgentConnected }) => ({
    color: isAgentConnected
      ? isRunning
          ? theme.palette.messageTypeColors.success
          : theme.palette.messageTypeColors.error
      : theme.palette.text.secondary
  }),
  socketStatus: ({ isSocketWorking }) => ({
    color: isSocketWorking === PROCESS_SOCKET_STATUS.RUNNING.value
      ? theme.palette.messageTypeColors.success
      : isSocketWorking === PROCESS_SOCKET_STATUS.STOPPED.value
        ? theme.palette.messageTypeColors.error
        : theme.palette.text.secondary
  }),
  cardAgentHeader: {
    margin: 0,
    fontSize: 20
  },
  statusBadge: ({ isAgentConnected }) => ({
    marginRight: 10,
    borderRadius: '100%',
    paddingLeft: 10,
    paddingTop: 10,
    backgroundColor: isAgentConnected
      ? theme.palette.messageTypeColors.success
      : theme.palette.messageTypeColors.error
  })
})
