import {
  format as dateFnsFormat,
  parseISO as dateFnsParseISO,
  parse as dateFnsParse,
  addMilliseconds as dateFnsAddMilliseconds,
  addSeconds as dateFnsAddSeconds,
  addMinutes as dateFnsAddMinutes,
  addHours as dateFnsAddHours,
  addDays as dateFnsAddDays,
  addMonths as dateFnsAddMonths,
  addYears as dateFnsAddYears,
  differenceInMilliseconds as dateFnsDiffInMS,
  differenceInSeconds as dateFnsDiffInSeconds,
  differenceInMinutes as dateFnsDiffInMinutes,
  differenceInHours as dateFnsDiffInHours,
  differenceInYears as dateFnsDiffInYears,
  formatRelative as dateFnsFormatRelative,
  formatDuration as dateFnsFormatDuration,
  formatDistance as dateFnsFormatDistance,
  isSameDay as dateFnsIsSameDay
} from 'date-fns'

// core
export const format = (date, format) => dateFnsFormat(date, format)

export const parseISO = date => dateFnsParseISO(date)

export const parse = (dateString, formatString, referenceDate = new Date()) => dateFnsParse(dateString, formatString, referenceDate)

export const addMilliseconds = (date, milliseconds) => dateFnsAddMilliseconds(date, milliseconds)

export const addSeconds = (date, seconds) => dateFnsAddSeconds(date, seconds)

export const addMinutes = (date, minutes) => dateFnsAddMinutes(date, minutes)

export const addHours = (date, hours) => dateFnsAddHours(date, hours)

export const addDays = (date, days) => dateFnsAddDays(date, days)

export const addMonths = (date, months) => dateFnsAddMonths(date, months)

export const addYears = (date, years) => dateFnsAddYears(date, years)

export const differenceInMilliseconds = (date1, date2) => dateFnsDiffInMS(date1, date2)

export const differenceInSeconds = (date1, date2) => dateFnsDiffInSeconds(date1, date2)

export const differenceInMinutes = (date1, date2) => dateFnsDiffInMinutes(date1, date2)

export const differenceInHours = (date1, date2) => dateFnsDiffInHours(date1, date2)

export const differenceInYears = (date1, date2) => dateFnsDiffInYears(date1, date2)

export const formatRelative = (date, baseDate) => dateFnsFormatRelative(date, baseDate)

export const formatDuration = duration => dateFnsFormatDuration(duration)

export const formatDistance = (date1, date2, options = {
  addSuffix: true,
  includeSeconds: true
}) => dateFnsFormatDistance(date1, date2, options)

export const isSameDay = (date1, date2) => dateFnsIsSameDay(date1, date2)

// custom
export const formatEventDate = eventDate => format(eventDate, "MM/dd/yyyy 'at' HH:mm") // formatRelative(eventDate, new Date())

export const formatEventDuration = durationInMinutes => formatDuration(
  { minutes: durationInMinutes },
  ['Y', 'MT', 'W', 'D', 'H', 'M', 'S']
)

export const formatBriefNoteDate = (rawBriefNoteDate, formattedBriefNoteDate) => {
  if (differenceInHours(new Date(), rawBriefNoteDate) > 24) {
    return formattedBriefNoteDate
  }

  return formatDistance(rawBriefNoteDate, new Date())
}
