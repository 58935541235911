import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Box from '@material-ui/core/Box'
import {
  Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, AreaChart, Area
} from 'recharts'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useIntl, FormattedMessage } from 'react-intl'
import style from './style'
import { selectCurrentProcess } from '../../../redux/selectors/Processes'
import { addDays, format } from '../../../utilities/date'
import { BYTE_TO_MB } from '../../../constants/general'
import TextField from '@material-ui/core/TextField'
import ProcessStatusService from '../../../services/ProcessStatus'
import FilterParameter from '../../../utilities/lists/FilterParameter'
import SortParameter from '../../../utilities/lists/SortParameter'
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer'
import { setCurrentProcess } from '../../../redux/actions/Processes'
import CmtCard from '../../../@coremat/CmtCard'

const useStyles = makeStyles(style)

const MEMORY_STATS_X_KEY = 'createdAt'
const MEMORY_STATS_Y_KEY = 'memoryUsage'

const CPU_STATS_X_KEY = 'createdAt'
const CPU_STATS_Y_KEY = 'cpuUsage'

const SOCKET_STATS_X_KEY = 'createdAt'
const SOCKET_STATS_Y_KEY = 'nrOfConnectedSockets'

const CONNECTION_ERROR_STATS_X_KEY = 'createdAt'
const CONNECTION_ERROR_STATS_Y_KEY = 'nrOfTooManyDBConnectionsError'

const formatDateShort = date => format(new Date(date), 'dd. MMM')

const formatMemoryXAxis = date => formatDateShort(date)

const formatCPUXAxis = date => formatDateShort(date)

const formatSocketXAxis = date => formatDateShort(date)

const formatConnectionErrorXAxis = date => formatDateShort(date)

const formatDateLong = date => format(new Date(date), 'dd/MM/yyyy HH:mm:ss')

const ProcessStatistics = () => {
  const classes = useStyles()
  const currentProcess = useSelector(selectCurrentProcess)
  const intl = useIntl()
  const [fromDateTimestamp, setFromDateTimestamp] = useState(addDays(new Date(), -1).getTime())
  const [toDateTimestamp, setToDateTimestamp] = useState(new Date().getTime())
  const [processStatusesRaw, setProcessStatusesRaw] = useState([])
  const dispatch = useDispatch()

  const breadcrumbs = useMemo(() => (
    [
      { label: <FormattedMessage id='sidebar.main' />, link: '/' },
      {
        label: <FormattedMessage id='pages.processStatistics' values={{ processName: currentProcess?.name }} />,
        isActive: true
      }
    ]
  ), [currentProcess])

  useEffect(
    () => {
      (async () => {
        if (currentProcess?.id) {
          const result = await ProcessStatusService.FindMany(
            -1,
            0,
            FilterParameter.FilterParameterCollectionToPlainFilterParameterCollection([
              new FilterParameter(
                ['processId'],
                currentProcess?.id,
                FilterParameter.FILTER_TYPES.EXACT_MATCH
              ),
              new FilterParameter(
                ['createdAt'],
                new Date(fromDateTimestamp).toISOString(),
                FilterParameter.FILTER_TYPES.GREATER_THAN_OR_EQUAL
              ),
              new FilterParameter(
                ['createdAt'],
                new Date(toDateTimestamp).toISOString(),
                FilterParameter.FILTER_TYPES.LESS_THAN_OR_EQUAL
              )
            ]),
            SortParameter.SortParameterCollectionToPlainSortParameterCollection([
              new SortParameter('createdAt', SortParameter.SORT_TYPES.ASC)
            ])
          )
          setProcessStatusesRaw(result.items)
        }
      })()
    },
    [fromDateTimestamp, toDateTimestamp, currentProcess?.id, setProcessStatusesRaw]
  )

  useEffect(() => () => dispatch(setCurrentProcess(null)), [dispatch]) // reset current process when unmounting the page

  const processStatuses = useMemo(() => processStatusesRaw
    .map(
      processStatus => ({
        createdAt: processStatus.createdAt,
        [MEMORY_STATS_Y_KEY]: processStatus.memoryUsage / BYTE_TO_MB,
        [CPU_STATS_Y_KEY]: processStatus.cpuUsage,
        [SOCKET_STATS_Y_KEY]: processStatus.nrOfOpenSockets,
        [CONNECTION_ERROR_STATS_Y_KEY]: processStatus.nrOfTooManyDBConnectionsError
      })
    ),
  [processStatusesRaw, fromDateTimestamp, toDateTimestamp]
  )

  const [maxProcessMemoryMB, maxProcessSockets, maxTooManyConnectionsErrors] = useMemo(() => {
    const maxValues = processStatuses?.reduce(
      (maxValues, processStatus) => ([
        processStatus[MEMORY_STATS_Y_KEY] > maxValues[0] ? processStatus[MEMORY_STATS_Y_KEY] : maxValues[0],
        processStatus[SOCKET_STATS_Y_KEY] > maxValues[1] ? processStatus[SOCKET_STATS_Y_KEY] : maxValues[1],
        processStatus[CONNECTION_ERROR_STATS_Y_KEY] > maxValues[1] ? processStatus[CONNECTION_ERROR_STATS_Y_KEY] : maxValues[2]
      ]),
      [0, 0, 0]
    )
    const defaultMemoryLimit = currentProcess?.memoryLimitMb || 100
    const defaultSocketLimit = 1
    const defaultConnectionErrorLimit = 0

    return [
      maxValues[0] > defaultMemoryLimit ? Math.ceil(maxValues[0] * 1.1) : defaultMemoryLimit,
      maxValues[1] > defaultSocketLimit ? Math.ceil(maxValues[1] * 1.1) : defaultSocketLimit,
      maxValues[2] > defaultConnectionErrorLimit ? Math.ceil(maxValues[2] * 1.1) : defaultConnectionErrorLimit
    ]
  }, [currentProcess?.memoryLimitMb, processStatuses])

  const formatMemoryValue = useCallback(value => [`${value.toFixed(2)} MB`, undefined], [])

  const formatCpuValue = useCallback(value => [`${value} %`, undefined], [])

  const formatSocketValue = useCallback(value => [value !== null ? value : '?', undefined], [])

  const setFromFilter = useCallback(e => {
    const fromDateTimestamp = new Date(e.target.value).getTime()
    if (fromDateTimestamp < toDateTimestamp) {
      setFromDateTimestamp(fromDateTimestamp)
    }
  }, [setFromDateTimestamp, toDateTimestamp])

  const setToFilter = useCallback(e => {
    const toDateTimestamp = new Date(e.target.value).getTime()
    if (fromDateTimestamp < toDateTimestamp) {
      setToDateTimestamp(toDateTimestamp)
    }
  }, [setToDateTimestamp, fromDateTimestamp])

  return (
    <PageContainer
      heading={<FormattedMessage
        id='ProcessStatsDialog.title'
        values={{ processName: currentProcess?.name }}
               />}
      breadcrumbs={breadcrumbs}
    >
      <Box width='100%' mb={3} display='flex'>
        <Box mr={2} ml='auto'>
          <TextField
            type='datetime-local'
            value={format(fromDateTimestamp, 'yyyy-MM-dd hh:mm')}
            onChange={setFromFilter}
          />
        </Box>
        <TextField
          type='datetime-local'
          value={format(toDateTimestamp, 'yyyy-MM-dd hh:mm')}
          onChange={setToFilter}
        />
      </Box>
      <Box width='100%' mt={4}>
        <CmtCard className={classes.statsCard}>
          <ResponsiveContainer width='100%' aspect={3}>
            <LineChart data={processStatuses} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
              <XAxis
                dataKey={MEMORY_STATS_X_KEY}
                tickFormatter={formatMemoryXAxis}
              />
              <YAxis
                label={{
                  value: intl.formatMessage({ id: 'ProcessStatsDialog.memoryUsageYAxisLabel' }),
                  angle: -90,
                  dx: -20
                }}
                domain={[0, maxProcessMemoryMB]}
              />
              <Tooltip
                labelStyle={{ color: 'black' }}
                itemStyle={{ color: 'black' }}
                cursor={false}
                formatter={formatMemoryValue}
                labelFormatter={formatDateLong}
              />
              <Line type='linear' dataKey={MEMORY_STATS_Y_KEY} stroke='#1e88e5' dot={false} strokeWidth={1} />
            </LineChart>
          </ResponsiveContainer>
        </CmtCard>
      </Box>
      <Box width='100%' mt={3}>
        <CmtCard className={classes.statsCard}>
          <ResponsiveContainer width='100%' aspect={3}>
            <LineChart data={processStatuses} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
              <XAxis
                dataKey={CPU_STATS_X_KEY}
                tickFormatter={formatCPUXAxis}
              />
              <YAxis
                label={{
                  value: intl.formatMessage({ id: 'ProcessStatsDialog.cpuUsageYAxisLabel' }),
                  angle: -90,
                  dx: -10
                }}
                domain={[0, 100]}
              />
              <Tooltip
                labelStyle={{ color: 'black' }}
                itemStyle={{ color: 'black' }}
                cursor={false}
                formatter={formatCpuValue}
                labelFormatter={formatDateLong}
              />
              <Line type='linear' dataKey={CPU_STATS_Y_KEY} stroke='#e5881e' dot={false} strokeWidth={1} />
            </LineChart>
          </ResponsiveContainer>
        </CmtCard>
      </Box>
      <Box width='100%' mt={3}>
        <CmtCard className={classes.statsCard}>
          <ResponsiveContainer width='100%' aspect={3}>
            <LineChart data={processStatuses} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
              <XAxis
                dataKey={SOCKET_STATS_X_KEY}
                tickFormatter={formatSocketXAxis}
              />
              <YAxis
                label={{
                  value: intl.formatMessage({ id: 'ProcessStatsDialog.nrOfSocketsYAxisLabel' }),
                  angle: -90,
                  dx: -10
                }}
                domain={[0, maxProcessSockets]}
              />
              <Tooltip
                labelStyle={{ color: 'black' }}
                itemStyle={{ color: 'black' }}
                cursor={false}
                formatter={formatSocketValue}
                labelFormatter={formatDateLong}
              />
              <Line type='linear' dataKey={SOCKET_STATS_Y_KEY} stroke='#e51e88' dot={false} strokeWidth={1} />
            </LineChart>
          </ResponsiveContainer>
        </CmtCard>
      </Box>
      <Box width='100%' mt={3}>
        <CmtCard className={classes.statsCard}>
          <ResponsiveContainer width='100%' aspect={3}>
            <LineChart data={processStatuses} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
              <XAxis
                dataKey={CONNECTION_ERROR_STATS_X_KEY}
                tickFormatter={formatConnectionErrorXAxis}
              />
              <YAxis
                label={{
                  value: intl.formatMessage({ id: 'ProcessStatsDialog.nrOfTooManyConnectionsErrorsYAxisLabel' }),
                  angle: -90,
                  dx: -10
                }}
                domain={[0, maxTooManyConnectionsErrors]}
              />
              <Tooltip
                labelStyle={{ color: 'black' }}
                itemStyle={{ color: 'black' }}
                cursor={false}
                formatter={formatSocketValue}
                labelFormatter={formatDateLong}
              />
              <Line type='linear' dataKey={CONNECTION_ERROR_STATS_Y_KEY} stroke='#5fe087' dot={false} strokeWidth={1} />
            </LineChart>
          </ResponsiveContainer>
        </CmtCard>
      </Box>
    </PageContainer>
  )
}

export default React.memo(ProcessStatistics)
