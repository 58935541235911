import webSocketClient from '../clients/webSocketClient'
import { WS_API_EVENTS } from '../constants/api'
import { withStandardWSResponseHandling } from '../utilities/responseHandling'

const WithProcessesFindMany = async (limit = -1, page = 0, filterBy = [], orderBy = []) =>
  webSocketClient.emitAsync(
    WS_API_EVENTS.OUTGOING.AGENT_PROCESS_FIND_MANY,
    {
      limit,
      page,
      filterBy,
      orderBy
    }
  )

const Update = async (agentId, { name, notificationsEnabled }) =>
  webSocketClient.emitAsync(
    WS_API_EVENTS.OUTGOING.AGENT_UPDATE,
    agentId,
    { name, notificationsEnabled }
  )

const OnAgentUpdated = listener => webSocketClient.socket.on(WS_API_EVENTS.INCOMING.AGENT_UPDATED, listener)

const OffAgentUpdated = listener => webSocketClient.socket.off(WS_API_EVENTS.INCOMING.AGENT_UPDATED, listener)

const AgentService = {
  WithProcessesFindMany: withStandardWSResponseHandling(WithProcessesFindMany),
  Update: withStandardWSResponseHandling(Update),
  OnAgentUpdated,
  OffAgentUpdated
}

export default AgentService
