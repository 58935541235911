import { commonHandlers, createReducer } from '../../utilities/store'
import GLOBAL_TYPES from '../types/Global'
import PROCESSES_TYPES from '../types/Processes'

const initialState = {
  currentProcess: null
}

const handlers = {
  [PROCESSES_TYPES.SET_CURRENT_PROCESS]: commonHandlers.setValue('currentProcess'),
  [GLOBAL_TYPES.RESET_STORE]: commonHandlers.resetState(initialState)
}

export default createReducer(
  initialState,
  handlers
)
