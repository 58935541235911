import { commonHandlers, createReducer } from '../../utilities/store'
import GLOBAL_TYPES from '../types/Global'
import AGENT_JAILS_TYPES from '../types/AgentJails'

const initialState = {
  list: []
}

const handlers = {
  [AGENT_JAILS_TYPES.UPSERT]: (state, action) => ({
    ...state,
    list: state.list.find(({ agentId }) => agentId === action.data.jails.agentId)
      ? state.list.map(
          agentJails => agentJails.agentId === action.data.jails.agentId // update existing action jails
            ? action.data.jails
            : agentJails
        )
      : [...state.list, action.data.jails] // new agent jails item
  }),
  [GLOBAL_TYPES.RESET_STORE]: commonHandlers.resetState(initialState)
}

export default createReducer(
  initialState,
  handlers
)
