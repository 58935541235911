import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Box from '@material-ui/core/Box'
import {
  Line, LineChart, ResponsiveContainer, XAxis, YAxis, Legend
} from 'recharts'
import { useSelector } from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { FormattedMessage } from 'react-intl'
import style from './style'
import { selectCurrentProcess } from '../../../redux/selectors/Processes'
import { addDays, format } from '../../../utilities/date'
import TextField from '@material-ui/core/TextField'
import FilterParameter from '../../../utilities/lists/FilterParameter'
import SortParameter from '../../../utilities/lists/SortParameter'
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer'
import CmtCard from '../../../@coremat/CmtCard'
import { useSearchParams } from 'react-router-dom'
import ActivityManagementService from '../../../services/ActivityManagement'

const useStyles = makeStyles(style)

const X_KEY = 'createdAt'
const TOTAL_NR_OF_USERS_Y_KEY = 'totalNrOfUsers'
const NR_OF_ACTIVE_USERS_Y_KEY = 'nrOfActiveUsers'
const NR_OF_DEVICES_INACTIVE_SINCE_LAST_MONTH_Y_KEY = 'nrOfDevicesInactiveSinceLastMonth'
const NR_OF_DEVICES_INACTIVE_SINCE_LAST_WEEK_Y_KEY = 'nrOfDevicesInactiveSinceLastWeek'
const NR_OF_GPS_TRACKERS_INACTIVE_SINCE_LAST_MONTH_Y_KEY = 'nrOfGPSTrackersInactiveSinceLastMonth'
const NR_OF_GPS_TRACKERS_INACTIVE_SINCE_LAST_WEEK_Y_KEY = 'nrOfGPSTrackersInactiveSinceLastWeek'
const NR_OF_USERS_INACTIVE_SINCE_LAST_MONTH_Y_KEY = 'nrOfUsersInactiveSinceLastMonth'
const NR_OF_USERS_INACTIVE_SINCE_LAST_WEEK_Y_KEY = 'nrOfUsersInactiveSinceLastWeek'
const TOTAL_NR_OF_DEVICES_Y_KEY = 'totalNrOfDevices'
const TOTAL_NR_OF_GPS_TRACKERS_Y_KEY = 'totalNrOfGPSTrackers'
const NR_OF_ACTIVE_DEVICES_Y_KEY = 'nrOfActiveDevices'
const NR_OF_ACTIVE_GPS_TRACKERS_Y_KEY = 'nrOfActiveGPSTrackers'

const chartConfigByStatisticsCategory = {
  users: [
    {
      yKey: TOTAL_NR_OF_USERS_Y_KEY,
      color: '#81ee58',
      label: 'Total'
    },
    {
      yKey: NR_OF_ACTIVE_USERS_Y_KEY,
      color: '#e51e88',
      label: 'Active'
    },
    {
      yKey: NR_OF_USERS_INACTIVE_SINCE_LAST_MONTH_Y_KEY,
      color: '#1e88e5',
      label: 'Inactive last month'
    },
    {
      yKey: NR_OF_USERS_INACTIVE_SINCE_LAST_WEEK_Y_KEY,
      color: '#e5881e',
      label: 'Inactive last week'
    }
  ],
  devices: [
    {
      yKey: TOTAL_NR_OF_DEVICES_Y_KEY,
      color: '#81ee58',
      label: 'Total'
    },
    {
      yKey: NR_OF_ACTIVE_DEVICES_Y_KEY,
      color: '#e51e88',
      label: 'Active'
    },
    {
      yKey: NR_OF_DEVICES_INACTIVE_SINCE_LAST_MONTH_Y_KEY,
      color: '#1e88e5',
      label: 'Inactive last month'
    },
    {
      yKey: NR_OF_DEVICES_INACTIVE_SINCE_LAST_WEEK_Y_KEY,
      color: '#e5881e',
      label: 'Inactive last week'
    }
  ],
  gpsTrackers: [
    {
      yKey: TOTAL_NR_OF_GPS_TRACKERS_Y_KEY,
      color: '#81ee58',
      label: 'Total'
    },
    {
      yKey: NR_OF_ACTIVE_GPS_TRACKERS_Y_KEY,
      color: '#e51e88',
      label: 'Active'
    },
    {
      yKey: NR_OF_GPS_TRACKERS_INACTIVE_SINCE_LAST_MONTH_Y_KEY,
      color: '#1e88e5',
      label: 'Inactive last month'
    },
    {
      yKey: NR_OF_GPS_TRACKERS_INACTIVE_SINCE_LAST_WEEK_Y_KEY,
      color: '#e5881e',
      label: 'Inactive last week'
    }
  ]
}

const formatDateShort = date => format(new Date(date), 'dd. MMM')

const formatXAxis = date => formatDateShort(date)

const ProcessActivityStatistics = () => {
  const classes = useStyles()
  const [fromDateTimestamp, setFromDateTimestamp] = useState(addDays(new Date(), -1).getTime())
  const [toDateTimestamp, setToDateTimestamp] = useState(new Date().getTime())
  const [processActivitiesRaw, setProcessActivitiesRaw] = useState([])
  const currentProcess = useSelector(selectCurrentProcess)
  const [searchParams] = useSearchParams()

  const breadcrumbs = useMemo(() => (
    [
      { label: <FormattedMessage id="sidebar.main" />, link: '/' },
      {
        label: <FormattedMessage id="pages.processActivityStatistics" values={{ processName: currentProcess?.name }} />,
        isActive: true
      }
    ]
  ), [currentProcess])

  const legendPayload = useMemo(
    () => {
      const statisticsCategory = searchParams.get('statisticsCategory')
      return statisticsCategory && chartConfigByStatisticsCategory[statisticsCategory]
        ? chartConfigByStatisticsCategory[statisticsCategory].map(
          chartConfig => ({
            value: chartConfig.label,
            type: 'line',
            id: chartConfig.yKey,
            color: chartConfig.color
          })
        )
        : []
    },
    [searchParams]
  )

  useEffect(
    () => {
      (async () => {
        const agentId = searchParams.get('agentId')
        if (agentId) {
          const result = await ActivityManagementService.FindMany(
            -1,
            0,
            FilterParameter.FilterParameterCollectionToPlainFilterParameterCollection([
              new FilterParameter(
                ['agentId'],
                agentId,
                FilterParameter.FILTER_TYPES.EXACT_MATCH
              ),
              new FilterParameter(
                ['createdAt'],
                new Date(fromDateTimestamp).toISOString(),
                FilterParameter.FILTER_TYPES.GREATER_THAN_OR_EQUAL
              ),
              new FilterParameter(
                ['createdAt'],
                new Date(toDateTimestamp).toISOString(),
                FilterParameter.FILTER_TYPES.LESS_THAN_OR_EQUAL
              )
            ]),
            SortParameter.SortParameterCollectionToPlainSortParameterCollection([
              new SortParameter('createdAt', SortParameter.SORT_TYPES.ASC)
            ])
          )
          setProcessActivitiesRaw(result.items)
        }
      })()
    },
    [fromDateTimestamp, toDateTimestamp, searchParams, setProcessActivitiesRaw]
  )

  const processActivities = useMemo(() => processActivitiesRaw
      .map(
        processActivity => chartConfigByStatisticsCategory[searchParams.get('statisticsCategory')].reduce(
          (processActivityChartData, chartConfig) => {
            processActivityChartData[chartConfig.yKey] = processActivity[chartConfig.yKey]
            return processActivityChartData
          },
          {
            createdAt: processActivity.createdAt
          }
        )
      ),
    [processActivitiesRaw, searchParams]
  )

  const maxValue = useMemo(() => {
    const statisticsCategory = searchParams.get('statisticsCategory')
    if (statisticsCategory) {
      const maxValue = processActivities?.reduce(
        (maxValue, activity) => {
          const maxValueOfCurrentActivity = chartConfigByStatisticsCategory[statisticsCategory].reduce(
            (maxValueOfCurrentActivity, chartConfig) => activity[chartConfig.yKey] > maxValueOfCurrentActivity
              ? activity[chartConfig.yKey]
              : maxValueOfCurrentActivity,
            0
          )
          return maxValueOfCurrentActivity > maxValue
            ? maxValueOfCurrentActivity
            : maxValue
        },
        0
      )

      return Math.ceil(maxValue * 1.1)
    }

    return 0
  }, [processActivities, searchParams])

  const tickArray = useMemo(
    () => Array.from(Array(maxValue + 1), (value, i) => i),
    [maxValue]
  )

  const setFromFilter = useCallback(e => {
    const fromDateTimestamp = new Date(e.target.value).getTime()
    if (fromDateTimestamp < toDateTimestamp) {
      setFromDateTimestamp(fromDateTimestamp)
    }
  }, [setFromDateTimestamp, toDateTimestamp])

  const setToFilter = useCallback(e => {
    const toDateTimestamp = new Date(e.target.value).getTime()
    if (fromDateTimestamp < toDateTimestamp) {
      setToDateTimestamp(toDateTimestamp)
    }
  }, [setToDateTimestamp, fromDateTimestamp])

  return (
    <PageContainer
      heading={(
        <FormattedMessage
          id="ProcessActivityStatsDialog.title"
          values={{ processName: currentProcess?.name }}
        />
      )}
      breadcrumbs={breadcrumbs}
    >
      <Box width="100%" mb={3} display="flex">
        <Box mr={2} ml="auto">
          <TextField
            type="datetime-local"
            value={format(fromDateTimestamp, 'yyyy-MM-dd hh:mm')}
            onChange={setFromFilter}
          />
        </Box>
        <TextField
          type="datetime-local"
          value={format(toDateTimestamp, 'yyyy-MM-dd hh:mm')}
          onChange={setToFilter}
        />
      </Box>
      <Box width="100%" mt={3}>
        <CmtCard className={classes.statsCard}>
          <ResponsiveContainer width="100%" aspect={2}>
            <LineChart data={processActivities} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
              <XAxis
                dataKey={X_KEY}
                tickFormatter={formatXAxis}
              />
              <YAxis
                domain={[0, maxValue]}
                allowDecimal={false}
                ticks={tickArray}
              />
              <Legend
                payload={legendPayload}
              />
              {chartConfigByStatisticsCategory[searchParams.get('statisticsCategory')].map(config => (
                <Line
                  key={config.yKey}
                  type="linear"
                  dataKey={config.yKey}
                  stroke={config.color}
                  dot={false}
                  strokeWidth={2}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </CmtCard>
      </Box>
    </PageContainer>
  )
}

export default React.memo(ProcessActivityStatistics)
