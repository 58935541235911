import React, { useEffect, useState } from 'react'
import GridContainer from '../../../@jumbo/components/GridContainer'
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer'
import IntlMessages from '../../../@jumbo/utils/IntlMessages'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import TableCell from '@material-ui/core/TableCell'
import ExecutedCommandTableToolbar from './components/ExecutedCommandTableToolbar'
import ExecutedCommandTableHead from './components/ExecutedCommandTableHead'
import ExecutedCommandListRow from './components/ExecutedCommandListRow'
import NoRecordFound from './components/NoRecordFound'
import useStyles from './index.style'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from '../../../@jumbo/utils/commonHelper'
import { getComparator, stableSort } from '../../../@jumbo/utils/tableHelper'
import { selectExecutedCommands } from '../../../redux/selectors/ExecutedCommands'
import { fetchExecutedCommands } from '../../../redux/actions/ExecutedCommands'

const breadcrumbs = [
  { label: <IntlMessages id='sidebar.main' />, link: '/' },
  { label: <IntlMessages id='pages.executedCommands' />, isActive: true }
]

const ExecutedCommands = () => {
  const classes = useStyles()
  const { list: executedCommands } = useSelector(selectExecutedCommands)
  const [orderBy, setOrderBy] = useState('executionDate')
  const [order, setOrder] = useState('desc')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [executedCommandsFetched, setExecutedCommandsFetched] = useState(false)
  const [isFilterApplied/*, setFilterApplied */] = useState(false)
  const [filterOptions, setFilterOptions] = React.useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      fetchExecutedCommands(/* filterOptions, debouncedSearchTerm, () => {
        setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm)
        setExecutedCommandsFetched(true)
      } */)
    )
    setExecutedCommandsFetched(true)
  }, [dispatch, filterOptions, debouncedSearchTerm])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrderBy(property)
    setOrder(isAsc ? 'desc' : 'asc')
  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <PageContainer heading={<IntlMessages id='pages.executedCommands' />} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <div style={{ marginTop: 24 }}>
            <div className={classes.root}>
              <Paper className={classes.paper}>
                <ExecutedCommandTableToolbar
                  filterOptions={filterOptions}
                  setFilterOptions={setFilterOptions}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
                <TableContainer className={classes.container}>
                  <Table
                    stickyHeader className={classes.table} aria-labelledby='tableTitle'
                    aria-label='sticky enhanced table'
                  >
                    <ExecutedCommandTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {executedCommands.length
                        ? (
                            stableSort(executedCommands, getComparator(order, orderBy))
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row, index) => (
                                <ExecutedCommandListRow
                                  key={index}
                                  row={row}
                                />
                              ))
                          )
                        : (
                          <TableRow style={{ height: 53 * 6 }}>
                            <TableCell colSpan={7} rowSpan={10}>
                              {isFilterApplied
                                ? (
                                  <NoRecordFound>There are no records found with your filter.</NoRecordFound>
                                  )
                                : (
                                  <NoRecordFound>{executedCommandsFetched ? 'There are no records found.' : 'Loading executed commands...'}</NoRecordFound>
                                  )}
                            </TableCell>
                          </TableRow>
                          )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component='div'
                  count={executedCommands.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </Paper>

            </div>
          </div>
        </Grid>
      </GridContainer>
    </PageContainer>
  )
}

export default ExecutedCommands
