import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import CmtCardHeader from '../../../../../@coremat/CmtCard/CmtCardHeader'
import { Box, IconButton, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core'
import { LockOpen } from '@material-ui/icons'
import CmtCard from '../../../../../@coremat/CmtCard'
import CmtCardContent from '../../../../../@coremat/CmtCard/CmtCardContent'
import List from '@material-ui/core/List'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  title: ({ hasIps }) => ({
    color: hasIps ? 'inherit' : theme.palette.success.dark
  })
}))

const JailItem = ({ name, bannedIPS, onUnban }) => {
  const handleUnban = useCallback(
    bannedIP => onUnban && onUnban(name, bannedIP),
    [name, onUnban]
  )

  const classes = useStyles({ hasIps: !!bannedIPS?.length })

  return (
    <CmtCard>
      <CmtCardHeader
        title={name}
        className={classes.title}
      />
      <CmtCardContent>
        <Box>
          {bannedIPS.length
            ? (
              <List dense>
                {bannedIPS.map(bannedIP => (
                  <ListItem key={bannedIP}>
                    <ListItemText primary={bannedIP} />
                    <ListItemSecondaryAction onClick={() => handleUnban(bannedIP)}>
                      <IconButton
                        edge='end'
                        aria-label='delete'
                      >
                        <LockOpen />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              )
            : (
              <Typography variant='body1'>
                <FormattedMessage
                  id='JailItem.NoIPsBannedLabel'
                  defaultMessage='No blocked IP addresses found'
                />
              </Typography>
              )}
        </Box>
      </CmtCardContent>
    </CmtCard>
  )
}

JailItem.propTypes = {
  name: PropTypes.string.isRequired,
  bannedIPS: PropTypes.arrayOf(PropTypes.string).isRequired,
  onUnban: PropTypes.func
}

export default React.memo(JailItem)
