import { makeActionCreator } from '../../utilities/store'
import USERS_TYPES from '../types/Users'

export const fetchUsers = makeActionCreator(USERS_TYPES.FETCH)

export const setUsers = makeActionCreator(USERS_TYPES.SET, 'list')

export const updateLocalUser = makeActionCreator(USERS_TYPES.UPDATE_LOCAL_USER, 'user')

export const deleteLocalUser = makeActionCreator(USERS_TYPES.DELETE_LOCAL_USER, 'user')

export const deleteUser = makeActionCreator(USERS_TYPES.DELETE_USER, 'user')

export const setCurrentUser = makeActionCreator(USERS_TYPES.SET_CURRENT_USER, 'currentUser')

export const createUser = makeActionCreator(USERS_TYPES.CREATE_USER, 'user', 'callback')

export const updateUser = makeActionCreator(USERS_TYPES.UPDATE_USER, 'userId', 'user', 'callback')

export const updateProfile = makeActionCreator(USERS_TYPES.UPDATE_PROFILE, 'profile', 'callback')
