import { call, take, all, fork } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import LogService from '../../services/log'

/**
 * Automatizes the creation of event channels(redux-saga.eventChannel) for certain async events, that can't be handled
 * inside the redux saga flow: for ex. we can't call yield put inside a ws event handler function, because the event
 * handler is outside the redux saga flow.
 * redux-saga.eventChannel resolves this problem, but the boilerplate is huge for handling a single event.
 * eventSubscriptionToSaga resolves the boilerplate problem thus connects ws event handlers to the saga flow through a
 * single function call.
 * @param subscribeHandler {function(function)}: function handling the subscription to the ws event.
 * @param unsubscribeHandler {function(function)}: function handling the unsubscription from the ws event.
 * @param eventHandler {function}: function handling the ws event.
 * @param setUpdateChannel {function(unknown)}: the passed function is called with the update channel object created for
 * the event subscription. This object can be used to unsubscribe from the ws events by calling updateChannel.close().
 * This parameter is used, because eventSubscriptionToSaga contains an infinite loop, so it's not possible to return the
 * update channel from it.
 * @returns {Generator<SimpleEffect<"TAKE", TakeEffectDescriptor>|void|SimpleEffect<"CALL", CallEffectDescriptor<EventChannel<unknown>>>|SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>, void, *>}
 */
export function * eventSubscriptionToSaga (
  subscribeHandler,
  unsubscribeHandler,
  eventHandler,
  setUpdateChannel
) {
  try {
    const updateChannel = yield call(() => eventChannel(emit => {
      const emitter = (...args) => emit(...args)
      subscribeHandler(emitter)

      return () => unsubscribeHandler(emitter) // return unsubscribe function
    }))

    setUpdateChannel(updateChannel) // send update channel back to the caller of the saga

    while (true) {
      try {
        const payload = yield take(updateChannel)
        yield call(eventHandler, payload)
      } catch (e) {
        yield LogService.error('An error occurred while handling an event from an event channel(eventSubscriptionToSaga)', e)
      }
    }
  } catch (e) {
    yield LogService.error('Subscription process has failed during the conversion of an event subscription to a saga(eventSubscriptionToSaga)', e)
  }
}

export class SagaUpdateChannelStore {
  constructor () {
    this.updateChannels = []
  }

  AddUpdateChannel = updateChannel => {
    this.updateChannels.push(updateChannel)
  }

  CloseUpdateChannels = function * () {
    yield all(
      this.updateChannels.map(
        updateChannel => fork(updateChannel.close)
      )
    )
    this.updateChannels = []
  }
}
