import React, { useEffect, useState } from 'react'
import GridContainer from '../../../@jumbo/components/GridContainer'
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer'
import ConfirmDialog from '../../../@jumbo/components/Common/ConfirmDialog'
import IntlMessages from '../../../@jumbo/utils/IntlMessages'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import TableCell from '@material-ui/core/TableCell'
import AddEditUser from './components/AddEditUser'
import UserDetailView from './components/UserDetailView'
import UserTableToolbar from './components/UserTableToolbar'
import UserTableHead from './components/UserTableHead'
import UserListRow from './components/UserListRow'
import NoRecordFound from './components/NoRecordFound'
import useStyles from './index.style'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from '../../../@jumbo/utils/commonHelper'
import { getComparator, stableSort } from '../../../@jumbo/utils/tableHelper'
import { selectUsers } from '../../../redux/selectors/Users'
import { deleteUser, fetchUsers, setCurrentUser } from '../../../redux/actions/Users'

const breadcrumbs = [
  { label: <IntlMessages id='sidebar.main' />, link: '/' },
  { label: <IntlMessages id='pages.users' />, isActive: true }
]

const Users = () => {
  const classes = useStyles()
  const { list: users } = useSelector(selectUsers)
  const [orderBy, setOrderBy] = useState('name')
  const [order, setOrder] = useState('asc')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selected, setSelected] = useState([])
  const [openViewDialog, setOpenViewDialog] = useState(false)
  const [openUserDialog, setOpenUserDialog] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [usersFetched, setUsersFetched] = useState(false)
  const [isFilterApplied/*, setFilterApplied */] = useState(false)
  const [filterOptions, setFilterOptions] = React.useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      fetchUsers(/* filterOptions, debouncedSearchTerm, () => {
        setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm)
        setUsersFetched(true)
      } */)
    )
    setUsersFetched(true)
  }, [dispatch, filterOptions, debouncedSearchTerm])

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false)
    dispatch(setCurrentUser(null))
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrderBy(property)
    setOrder(isAsc ? 'desc' : 'asc')
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = users.map(n => n.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleUserView = user => {
    dispatch(setCurrentUser(user))
    setOpenViewDialog(true)
  }

  const handleCloseViewDialog = () => {
    dispatch(setCurrentUser(null))
    setOpenViewDialog(false)
  }

  const handleUserEdit = user => {
    dispatch(setCurrentUser(user))
    setOpenUserDialog(true)
  }

  // user delete handlers
  const handleUserDelete = user => {
    setSelectedUser(user)
    setOpenConfirmDialog(true)
  }

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false)
    setSelectedUser(null)
    dispatch(deleteUser(selectedUser))
  }

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false)
    setSelectedUser(null)
  }

  const isSelected = id => selected.indexOf(id) !== -1

  return (
    <PageContainer heading={<IntlMessages id='pages.users' />} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <div style={{ marginTop: 24 }}>
            <div className={classes.root}>
              <Paper className={classes.paper}>
                <UserTableToolbar
                  selected={selected}
                  setSelected={setSelected}
                  onUserAdd={setOpenUserDialog}
                  filterOptions={filterOptions}
                  setFilterOptions={setFilterOptions}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
                <TableContainer className={classes.container}>
                  <Table
                    stickyHeader className={classes.table} aria-labelledby='tableTitle'
                    aria-label='sticky enhanced table'
                  >
                    <UserTableHead
                      classes={classes}
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={users.length}
                    />
                    <TableBody>
                      {users.length
                        ? (
                            stableSort(users, getComparator(order, orderBy))
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row, index) => (
                                <UserListRow
                                  key={index}
                                  row={row}
                                  onRowClick={handleRowClick}
                                  onUserEdit={handleUserEdit}
                                  onUserDelete={handleUserDelete}
                                  onUserView={handleUserView}
                                  isSelected={isSelected}
                                />
                              ))
                          )
                        : (
                          <TableRow style={{ height: 53 * 6 }}>
                            <TableCell colSpan={7} rowSpan={10}>
                              {isFilterApplied
                                ? (
                                  <NoRecordFound>There are no records found with your filter.</NoRecordFound>
                                  )
                                : (
                                  <NoRecordFound>{usersFetched ? 'There are no records found.' : 'Loading users...'}</NoRecordFound>
                                  )}
                            </TableCell>
                          </TableRow>
                          )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component='div'
                  count={users.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </Paper>

              {openUserDialog && <AddEditUser open={openUserDialog} onCloseDialog={handleCloseUserDialog} />}
              {openViewDialog && <UserDetailView open={openViewDialog} onCloseDialog={handleCloseViewDialog} />}

              <ConfirmDialog
                open={openConfirmDialog}
                title={`Confirm delete ${selectedUser?.name}`}
                content='Are you sure, you want to  delete this user?'
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
              />
            </div>
          </div>
        </Grid>
      </GridContainer>
    </PageContainer>
  )
}

export default Users
