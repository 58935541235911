import log from '../clients/logger'

const info = async (...args) => log.info(...args)

const warning = async (...args) => log.warn(...args)

const error = async (...args) => log.error(...args)

const debug = async (...args) => log.debug(...args)

const trace = async (...args) => log.trace(...args)

const LogService = {
  info,
  warning,
  error,
  debug,
  trace
}

export default LogService
