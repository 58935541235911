import React, { useContext } from 'react'
import { MenuItem, MenuList, Paper, Popover, Typography } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { AuhMethods } from '../../../../services/auth'
import { CurrentAuthMethod } from '../../../constants/AppConstants'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SettingsIcon from '@material-ui/icons/Settings'
import SidebarThemeContext from '../../../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext'
import { selectAuthUser } from '../../../../redux/selectors/Auth'
import { updateEditProfileDialogOpen } from '../../../../redux/actions/Auth'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '30px 16px 12px 16px',
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`
  },
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease'
    }
  },
  userTitle: {
    color: props => props.sidebarTheme.textDarkColor,
    marginBottom: 8
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25
  }
}))

const SidebarHeader = () => {
  const { sidebarTheme } = useContext(SidebarThemeContext)
  const classes = useStyles({ sidebarTheme })
  const dispatch = useDispatch()
  const authUser = useSelector(selectAuthUser)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const onLogoutClick = () => {
    handlePopoverClose()
    AuhMethods[CurrentAuthMethod].onLogout()(dispatch)
  }

  const handleProfileSettingsClick = () => {
    handlePopoverClose()
    dispatch(updateEditProfileDialogOpen(true))
  }

  return (
    <div className={classes.root}>
      {/* <CmtAvatar src='https://via.placeholder.com/150' alt='User Avatar' /> */}
      <div className={classes.userInfo} onClick={handlePopoverOpen}>
        <div
          className='pointer'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end'
          }}
        >
          <div className='mr-2'>
            <Typography className={classes.userTitle} component='h3' variant='h6'>
              {`${authUser?.firstName} ${authUser?.lastName}`}
            </Typography>
            <Typography className={classes.userSubTitle}>
              {authUser?.mail}
            </Typography>
          </div>
          <ArrowDropDownIcon />
        </div>
      </div>

      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          container={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
        >
          <Paper elevation={8}>
            <MenuList>
              <MenuItem onClick={handleProfileSettingsClick}>
                <SettingsIcon />
                <div className='ml-2'>Profile settings</div>
              </MenuItem>
              <MenuItem onClick={onLogoutClick}>
                <ExitToAppIcon />
                <div className='ml-2'>Logout</div>
              </MenuItem>
            </MenuList>
          </Paper>
        </Popover>
      )}
    </div>
  )
}

export default SidebarHeader
