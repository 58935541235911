const USERS_TYPES = {
  FETCH: '[users] fetch',
  SET: '[users] set',
  UPDATE_LOCAL_USER: '[users] updateLocalUser',
  DELETE_LOCAL_USER: '[users] deleteLocalUser',
  DELETE_USER: '[users] deleteUser',
  SET_CURRENT_USER: '[users] setCurrentUser',
  CREATE_USER: '[users] createUser',
  UPDATE_USER: '[users] updateUser',
  UPDATE_PROFILE: '[users] updateProfile'
}

export default USERS_TYPES
