import { all, fork, put, takeLatest } from 'redux-saga/effects'
import WS_EVENTS_TYPES from '../types/WSEvents'
import { eventSubscriptionToSaga, SagaUpdateChannelStore } from '../../utilities/store/sagas'
import { fetchStart, fetchSuccess } from '../actions'
import { handleRemoteApiError } from '../../utilities/responseHandling'
import AGENT_ACTIVITIES_TYPES from '../types/AgentActivities'
import { upsertAgentActivities } from '../actions/AgentActivities'
import ActivityManagementService from '../../services/ActivityManagement'

function * requestActivities () {
  try {
    yield put(fetchStart('requestActivities'))
    yield ActivityManagementService.RequestActivities()
    yield put(fetchSuccess(undefined, 'requestActivities'))
  } catch (e) {
    yield handleRemoteApiError(e, 'requestActivities', 'Failed to request activities')
  }
}

function * handleAgentActivityUpdate (data) {
  yield put(upsertAgentActivities(data))
}

const updateChannelStore = new SagaUpdateChannelStore()

function * subscribeToWSEvents () {
  yield all([
    fork(eventSubscriptionToSaga, ActivityManagementService.OnUpdatedMany, ActivityManagementService.OffUpdatedMany, handleAgentActivityUpdate, updateChannelStore.AddUpdateChannel)
  ])
}

function * unsubscribeFromWSEvents () {
  yield updateChannelStore.CloseUpdateChannels()
}

function * AgentActivitiesSaga () {
  yield takeLatest(AGENT_ACTIVITIES_TYPES.REQUEST, requestActivities)
  yield takeLatest(WS_EVENTS_TYPES.SUBSCRIBE, subscribeToWSEvents)
  yield takeLatest(WS_EVENTS_TYPES.UNSUBSCRIBE, unsubscribeFromWSEvents)
}

export default AgentActivitiesSaga
