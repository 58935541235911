import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { selectUsers } from '../../../../../redux/selectors/Users'
import { createUser, updateUser } from '../../../../../redux/actions/Users'
import EditUserDialog from '../../../../commonComponents/EditUserDialog'

const AddEditUser = ({ open, onCloseDialog }) => {
  const { currentUser } = useSelector(selectUsers)

  const dispatch = useDispatch()

  const onUserSave = userDetail => {
    dispatch(
      currentUser
        ? updateUser(currentUser.id, userDetail, onCloseDialog)
        : createUser(userDetail, onCloseDialog)
    )
  }

  return (
    <EditUserDialog
      open={open}
      onCloseDialog={onCloseDialog}
      user={currentUser}
      onUserSave={onUserSave}
    />
  )
}

export default AddEditUser

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
}
