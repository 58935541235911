import { commonHandlers, commonUpdateStrategies, createReducer } from '../../utilities/store'
import AGENT_PROCESSES_TYPES from '../types/AgentProcesses'
import AGENTS_TYPES from '../types/Agents'
import PROCESSES_TYPES from '../types/Processes'
import GLOBAL_TYPES from '../types/Global'

const initialState = {
  list: []
}

const handlers = {
  [AGENT_PROCESSES_TYPES.SET]: commonHandlers.setValue('list'),
  [AGENTS_TYPES.UPDATE_LOCAL_AGENT]: commonHandlers.mergeItemIntoArrayMember('list', 'agent'),
  [PROCESSES_TYPES.UPDATE_LOCAL_PROCESS]: commonHandlers.mergeItemIntoArrayMember(
    'list',
    'process',
    (item, actionItem) => item.id === actionItem.agentId,
    (updatedItem, actionItem) => ({
      ...updatedItem,
      Processes: updatedItem.Processes.map(
        process => process.id === actionItem.id
          ? ({
              ...process,
              ...actionItem,
              ProcessStatuses: [
                ...(actionItem.ProcessStatuses || []),
                ...process.ProcessStatuses
              ]
            })
          : process
      )
    })
  ),
  [GLOBAL_TYPES.RESET_STORE]: commonHandlers.resetState(initialState)
}

export default createReducer(
  initialState,
  handlers
)
