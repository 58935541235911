/* import JWTAuth from './jwt';
import BasicAuth from './Basic' */
import WSSManagementAuth from './wssManagement'
import httpClient from '../../clients/httpClient'
import { HTTP_API_ENDPOINTS } from '../../constants/api'

export const AuhMethods = {
  /* jwtAuth: JWTAuth,
  basic: BasicAuth, */
  wssManagement: WSSManagementAuth
}

export const Login = async (username, password) => {
  const result = await httpClient.post(
    HTTP_API_ENDPOINTS.LOGIN,
    {
      username,
      password
    }
  )

  return result.data.data
}

export const SendAuthCode = async (controlCode, sixDigitCode, sub) => {
  const result = await httpClient.post(
    HTTP_API_ENDPOINTS.VERIFY_AUTH_CODE,
    {
      controlCode,
      sixDigitCode,
      sub
    }
  )

  return result.data.data
}

export const Logout = async () => {
  const result = await httpClient.get(HTTP_API_ENDPOINTS.LOGOUT)

  return result.data
}

const GetAuthUser = async () => {
  const result = await httpClient.get(HTTP_API_ENDPOINTS.GET_AUTH_USER)

  return result.data
}

export const ResetPassword = async email => httpClient.post(
  HTTP_API_ENDPOINTS.RESET_PASSWORD,
  {
    email
  }
)

const AuthService = {
  Login,
  SendAuthCode,
  Logout,
  GetAuthUser,
  ResetPassword
}

export default AuthService
