import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../@jumbo/constants/ActionTypes'

const DEFAULT_FETCH_ID = 'globalFetch'

export const fetchSuccess = (message = '', fetchId = DEFAULT_FETCH_ID) => ({
  type: FETCH_SUCCESS,
  payload: {
    message,
    fetchId
  }
})

export const fetchError = (error = '', fetchId = DEFAULT_FETCH_ID) => ({
  type: FETCH_ERROR,
  payload: {
    error,
    fetchId
  }
})

export const fetchStart = (fetchId = DEFAULT_FETCH_ID) => ({
  type: FETCH_START,
  payload: {
    fetchId
  }
})
