import React, { useCallback, useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import GridContainer from '../../../@jumbo/components/GridContainer'
import Grid from '@material-ui/core/Grid'
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput'
import Button from '@material-ui/core/Button'
import { requiredMessage } from '../../../@jumbo/constants/ErrorMessages'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { FormattedMessage } from 'react-intl'
import style from './style'
import { updateProcess } from '../../../redux/actions/Processes'
import { selectCurrentProcess } from '../../../redux/selectors/Processes'
import AppSwitch from '../../../@jumbo/components/Common/formElements/AppSwitch'

const useStyles = makeStyles(style)

const EditProcessDialog = ({ isOpen, onClose }) => {
  const classes = useStyles()
  const currentProcess = useSelector(selectCurrentProcess)

  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [socketMonitoringEnabled, setSocketMonitoringEnabled] = useState(false)
  const [publicUrl, setPublicUrl] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    if (currentProcess) {
      const { name, socketMonitoringEnabled, publicUrl } = currentProcess
      setName(name)
      setSocketMonitoringEnabled(socketMonitoringEnabled)
      setPublicUrl(publicUrl)
    }
  }, [currentProcess, setName, setSocketMonitoringEnabled, setPublicUrl])

  const handleSave = useCallback(() => {
    const processDetail = {
      ...currentProcess,
      name,
      socketMonitoringEnabled,
      publicUrl
    }

    dispatch(
      updateProcess(
        processDetail,
        () => onClose()
      )
    )
  }, [currentProcess, name, socketMonitoringEnabled, publicUrl, dispatch])

  const handleSubmitClick = useCallback(() => {
    if (!name) {
      setNameError(requiredMessage)
    } else {
      handleSave()
    }
  }, [name, setNameError, handleSave])

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className={classes.dialogRoot}
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        <FormattedMessage id='EditProcessDialog.title' />
      </DialogTitle>
      <DialogContent dividers>
        <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }} alignItems='center' mb={{ xs: 6, md: 5 }}>
          <GridContainer>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                variant='outlined'
                label={(
                  <FormattedMessage id='EditProcessDialog.nameLabel' />
                )}
                value={name}
                onChange={e => {
                  setName(e.target.value)
                  setNameError('')
                }}
                helperText={nameError}
              />
            </Grid>
            <Grid item xs={12}>
              <AppSwitch
                label={(
                  <FormattedMessage id='EditProcessDialog.socketMonitoringEnabledLabel' />
                )}
                checked={socketMonitoringEnabled}
                onChange={event => setSocketMonitoringEnabled(event.target.checked)}
              />
            </Grid>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                variant='outlined'
                label={(
                  <FormattedMessage id='EditProcessDialog.publicUrlLabel' />
                )}
                value={publicUrl}
                onChange={e => setPublicUrl(e.target.value)}
                helperText={nameError}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box display='flex' justifyContent='flex-end' mb={4}>
          <Button onClick={onClose}>
            <FormattedMessage id='EditProcessDialog.cancelButtonLabel' />
          </Button>
          <Box ml={2}>
            <Button variant='contained' color='primary' onClick={handleSubmitClick}>
              <FormattedMessage id='EditProcessDialog.saveButtonLabel' />
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default React.memo(EditProcessDialog)

EditProcessDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
}
