import { makeActionCreator } from '../../utilities/store'
import PROCESSES_TYPES from '../types/Processes'

export const updateLocalProcess = makeActionCreator(PROCESSES_TYPES.UPDATE_LOCAL_PROCESS, 'process')

export const startProcess = makeActionCreator(PROCESSES_TYPES.START_PROCESS, 'processId')

export const stopProcess = makeActionCreator(PROCESSES_TYPES.STOP_PROCESS, 'processId')

export const restartProcess = makeActionCreator(PROCESSES_TYPES.RESTART_PROCESS, 'processId')

export const setCurrentProcess = makeActionCreator(PROCESSES_TYPES.SET_CURRENT_PROCESS, 'currentProcess')

export const updateProcess = makeActionCreator(PROCESSES_TYPES.UPDATE_PROCESS, 'process', 'callback')
