import { commonHandlers, createReducer } from '../../utilities/store'
import GLOBAL_TYPES from '../types/Global'
import EXECUTED_COMMANDS_TYPES from '../types/ExecutedCommands'

const initialState = {
  list: []
}

const handlers = {
  [EXECUTED_COMMANDS_TYPES.SET]: commonHandlers.setValue('list'),
  [GLOBAL_TYPES.RESET_STORE]: commonHandlers.resetState(initialState)
}

export default createReducer(
  initialState,
  handlers
)
