import log from 'loglevel'
import { ENVIRONMENT_TYPES, ENV, LOG_LEVEL_NAMES } from '../../constants/core'

log.setLevel(
  ENV === ENVIRONMENT_TYPES.PRODUCTION
    ? LOG_LEVEL_NAMES.ERROR
    : LOG_LEVEL_NAMES.DEBUG
)

export default log
