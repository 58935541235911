import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader'
import { Box, ListItem, ListItemText, Grid, IconButton } from '@material-ui/core'
import TimelineIcon from '@material-ui/icons/Timeline'
import CmtCard from '../../../../@coremat/CmtCard'
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent'
import List from '@material-ui/core/List'
import AccessibilityIcon from '@material-ui/icons/Accessibility'
import SmartphoneIcon from '@material-ui/icons/Smartphone'
import GPSIcon from '@material-ui/icons/GpsFixed'

const ActivityItem = ({
  totalNrOfUsers,
  nrOfActiveUsers,
  nrOfDevicesInactiveSinceLastMonth,
  nrOfDevicesInactiveSinceLastWeek,
  nrOfGPSTrackersInactiveSinceLastMonth,
  nrOfGPSTrackersInactiveSinceLastWeek,
  nrOfUsersInactiveSinceLastMonth,
  nrOfUsersInactiveSinceLastWeek,
  totalNrOfDevices,
  totalNrOfGPSTrackers,
  nrOfActiveDevices,
  nrOfActiveGPSTrackers,
  onUserActivityStatsClicked,
  onDeviceActivityStatsClicked,
  onTrackerActivityStatsClicked
}) => (
    <>
      <Grid item xs={12} md={4} xl={3}>
        <CmtCard>
          <CmtCardHeader
            title={(
              <>
                <AccessibilityIcon />
                <span>Users</span>
              </>
            )}
          >
            <IconButton color='primary' aria-label='statistics' component='span' onClick={onUserActivityStatsClicked}>
              <TimelineIcon />
            </IconButton>
          </CmtCardHeader>
          <CmtCardContent>
            <Box>
              <List dense>
                <ListItem key="totalNrOfUsers">
                  <ListItemText primary={(
                    <p>
                      <b>
                        Total nr. of users:
                      </b>{` ${totalNrOfUsers}`}
                    </p>
                  )} />
                </ListItem>
                <ListItem key="nrOfActiveUsers">
                  <ListItemText primary={(
                    <p>
                      <b>
                        Nr. of active users:
                      </b>{` ${nrOfActiveUsers}`}
                    </p>
                  )} />
                </ListItem>
                <ListItem key="nrOfUsersInactiveSinceLastWeek">
                  <ListItemText primary={(
                    <p>
                      <b>
                        Nr. of users inactive since last week:
                      </b>{` ${nrOfUsersInactiveSinceLastWeek}`}
                    </p>
                  )} />
                </ListItem>
                <ListItem key="nrOfUsersInactiveSinceLastMonth">
                  <ListItemText primary={(
                    <p>
                      <b>
                        Nr. of users inactive since last month:
                      </b>{` ${nrOfUsersInactiveSinceLastMonth}`}
                    </p>
                  )} />
                </ListItem>
              </List>
            </Box>
          </CmtCardContent>
        </CmtCard>
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <CmtCard>
          <CmtCardHeader
            title={(
              <>
                <SmartphoneIcon />
                <span>Devices</span>
              </>
            )}

          >
            <IconButton color='primary' aria-label='statistics' component='span' onClick={onDeviceActivityStatsClicked}>
              <TimelineIcon />
            </IconButton>
          </CmtCardHeader>
          <CmtCardContent>
            <List dense>
              <ListItem key="totalNrOfDevices">
                <ListItemText primary={(
                  <p>
                    <b>
                      Total nr. of devices:
                    </b>{` ${totalNrOfDevices}`}
                  </p>
                )} />
              </ListItem>
              <ListItem key="nrOfActiveDevices">
                <ListItemText primary={(
                  <p>
                    <b>
                      Nr. of active devices:
                    </b>{` ${nrOfActiveDevices}`}
                  </p>
                )} />
              </ListItem>
              <ListItem key="nrOfDevicesInactiveSinceLastWeek">
                <ListItemText primary={(
                  <p>
                    <b>
                      Nr. of devices inactive since last week:
                    </b>{` ${nrOfDevicesInactiveSinceLastWeek}`}
                  </p>
                )} />
              </ListItem>
              <ListItem key="nrOfDevicesInactiveSinceLastMonth">
                <ListItemText primary={(
                  <p>
                    <b>
                      Nr. of devices inactive since last month:
                    </b>{` ${nrOfDevicesInactiveSinceLastMonth}`}
                  </p>
                )} />
              </ListItem>
            </List>
          </CmtCardContent>
        </CmtCard>
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <CmtCard>
          <CmtCardHeader
            title={(
              <>
                <GPSIcon />
                <span>GPS Trackers</span>
              </>
            )}
          >
            <IconButton color='primary' aria-label='statistics' component='span' onClick={onTrackerActivityStatsClicked}>
              <TimelineIcon />
            </IconButton>
          </CmtCardHeader>
          <CmtCardContent>
            <List dense>
              <ListItem key="totalNrOfGPSTrackers">
                <ListItemText primary={(
                  <p>
                    <b>
                      Total nr. of GPS trackers:
                    </b>{` ${totalNrOfGPSTrackers}`}
                  </p>
                )} />
              </ListItem>
              <ListItem key="nrOfActiveGPSTrackers">
                <ListItemText primary={(
                  <p>
                    <b>
                      Nr. of active GPS trackers:
                    </b>{` ${nrOfActiveGPSTrackers}`}
                  </p>
                )} />
              </ListItem>
              <ListItem key="nrOfGPSTrackersInactiveSinceLastWeek">
                <ListItemText primary={(
                  <p>
                    <b>
                      Nr. of GPS trackers inactive since last week:
                    </b> {` ${nrOfGPSTrackersInactiveSinceLastWeek}`}
                  </p>
                )} />
              </ListItem>
              <ListItem key="nrOfGPSTrackersInactiveSinceLastMonth">
                <ListItemText primary={(
                  <p>
                    <b>
                      Nr. of GPS trackers inactive since last month:
                    </b> {` ${nrOfGPSTrackersInactiveSinceLastMonth}`}
                  </p>
                )} />
              </ListItem>
            </List>
          </CmtCardContent>
        </CmtCard>
      </Grid>
    </>
  )

ActivityItem.propTypes = {
  totalNrOfUsers: PropTypes.number,
  nrOfActiveUsers: PropTypes.number,
  nrOfDevicesInactiveSinceLastMonth: PropTypes.number,
  nrOfDevicesInactiveSinceLastWeek: PropTypes.number,
  nrOfGPSTrackersInactiveSinceLastMonth: PropTypes.number,
  nrOfGPSTrackersInactiveSinceLastWeek: PropTypes.number,
  nrOfUsersInactiveSinceLastMonth: PropTypes.number,
  nrOfUsersInactiveSinceLastWeek: PropTypes.number,
  totalNrOfDevices: PropTypes.number,
  totalNrOfGPSTrackers: PropTypes.number,
  nrOfActiveDevices: PropTypes.number,
  nrOfActiveGPSTrackers: PropTypes.number,
  onUserActivityStatsClicked: PropTypes.func,
  onDeviceActivityStatsClicked: PropTypes.func,
  onTrackerActivityStatsClicked: PropTypes.func
}

export default React.memo(ActivityItem)
