import React, { useEffect } from 'react'
import { Slide, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useDispatch, useSelector } from 'react-redux'
import PageLoader from '../PageComponents/PageLoader'
import { fetchError } from '../../../redux/actions'
import { selectCommon } from '../../../redux/selectors/Common'

function SlideTransition (props) {
  return <Slide {...props} direction='left' />
}

const ContentLoader = () => {
  const { error, loading, message } = useSelector(selectCommon)
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchError(''))
    }, 3000)
  }, [dispatch, error, message])

  return (
    <>
      {loading && <PageLoader />}
      <Snackbar
        open={Boolean(error)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant='filled' severity='error'>
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(message)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={SlideTransition}
      >
        <Alert variant='filled' severity='success'>
          {message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ContentLoader
