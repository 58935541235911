import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { updateProfile } from '../../../redux/actions/Users'
import { selectAuthUser } from '../../../redux/selectors/Auth'
import EditUserDialog from '../EditUserDialog'

const EditProfileDialog = ({ open, onCloseDialog }) => {
  const authUser = useSelector(selectAuthUser)

  const dispatch = useDispatch()

  const onUserSave = userDetail => {
    dispatch(
      updateProfile(userDetail, onCloseDialog)
    )
  }

  return (
    <EditUserDialog
      open={open}
      onCloseDialog={onCloseDialog}
      user={authUser}
      onUserSave={onUserSave}
    />
  )
}

export default EditProfileDialog

EditProfileDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
}
