import React, { useState } from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import useStyles from './index.style'

const ExecutedCommandTableToolbar = () => {
  const classes = useStyles()

  return (
    <>
      <Toolbar
        className={clsx(classes.root)}
      >
        <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
          Executed commands{' '}
        </Typography>
      </Toolbar>
    </>
  )
}

ExecutedCommandTableToolbar.propTypes = {
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func
}

export default React.memo(ExecutedCommandTableToolbar)
