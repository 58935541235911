import webSocketClient from '../clients/webSocketClient'
import { WS_API_EVENTS } from '../constants/api'
import { withStandardWSResponseHandling } from '../utilities/responseHandling'

const FindMany = async (limit = -1, page = 0, filterBy = [], orderBy = []) =>
  webSocketClient.emitAsync(
    WS_API_EVENTS.OUTGOING.USER_FIND_MANY,
    {
      limit,
      page,
      filterBy,
      orderBy
    }
  )

const Delete = async id =>
  webSocketClient.emitAsync(
    WS_API_EVENTS.OUTGOING.USER_DELETE,
    id
  )

const Create = async ({ firstName, lastName, mail, password, notificationsEnabled }) =>
  webSocketClient.emitAsync(
    WS_API_EVENTS.OUTGOING.USER_CREATE,
    { firstName, lastName, mail, password, notificationsEnabled }
  )

const Update = async (userId, { firstName, lastName, mail, password, notificationsEnabled }) =>
  webSocketClient.emitAsync(
    WS_API_EVENTS.OUTGOING.USER_UPDATE,
    userId,
    { firstName, lastName, mail, password, notificationsEnabled }
  )

const UpdateProfile = async ({ firstName, lastName, mail, password, notificationsEnabled }) =>
  webSocketClient.emitAsync(
    WS_API_EVENTS.OUTGOING.PROFILE_UPDATE,
    { firstName, lastName, mail, password, notificationsEnabled }
  )

const OnUserUpdated = listener => webSocketClient.socket.on(WS_API_EVENTS.INCOMING.USER_UPDATED, listener)

const OffUserUpdated = listener => webSocketClient.socket.off(WS_API_EVENTS.INCOMING.USER_UPDATED, listener)

const OnUserDeleted = listener => webSocketClient.socket.on(WS_API_EVENTS.INCOMING.USER_DELETED, listener)

const OffUserDeleted = listener => webSocketClient.socket.off(WS_API_EVENTS.INCOMING.USER_DELETED, listener)

const OnUserCreated = listener => webSocketClient.socket.on(WS_API_EVENTS.INCOMING.USER_CREATED, listener)

const OffUserCreated = listener => webSocketClient.socket.off(WS_API_EVENTS.INCOMING.USER_CREATED, listener)

const UserService = {
  FindMany: withStandardWSResponseHandling(FindMany),
  Delete: withStandardWSResponseHandling(Delete),
  Create: withStandardWSResponseHandling(Create),
  Update: withStandardWSResponseHandling(Update),
  UpdateProfile: withStandardWSResponseHandling(UpdateProfile),
  OnUserUpdated,
  OffUserUpdated,
  OnUserDeleted,
  OffUserDeleted,
  OnUserCreated,
  OffUserCreated
}

export default UserService
