import { io } from 'socket.io-client'
import Log from '../services/log'
import { API_CONFIG, SOCKET_IO_EVENTS } from '../constants/api'

const webSocketClient = new function () {
  this.socket = null

  this.connect = (token, onDisconnect) => new Promise((resolve, reject) => {
    this.socket = io(
      `${API_CONFIG.WS.URL}${API_CONFIG.WS.NAMESPACE}?${API_CONFIG.WS.TOKEN_QUERY_PARAM_NAME}=${token}`,
      {
        transports: ['websocket']
      }
    )

    this.socket.on(SOCKET_IO_EVENTS.CONNECT, socket => {
      resolve(socket)
    })

    this.socket.on(SOCKET_IO_EVENTS.CONNECT_ERROR, error => {
      reject(error)
    })

    this.socket.on(SOCKET_IO_EVENTS.DISCONNECT, socket => {
      Log.warning('Socket disconnected')
      onDisconnect && onDisconnect(socket)
    })
  })

  this.disconnect = () => {
    this.socket.disconnect()
  }

  /**
   * Async alternative to io.emit: instead of accessing the response to the emitted event in the callback(last parameter
   * of emit), it can be accessed through a Promise returned by the emitAsync call.
   * @param args
   * @returns {Promise<unknown>}
   */
  this.emitAsync = (...args) => new Promise(resolve => this.socket.emit(...args, resolve))
}()

export default webSocketClient
