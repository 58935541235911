import webSocketClient from '../clients/webSocketClient'
import { WS_API_EVENTS } from '../constants/api'
import { withStandardWSResponseHandling } from '../utilities/responseHandling'

const RequestActivities = async () => webSocketClient.emitAsync(WS_API_EVENTS.OUTGOING.ACTIVITY_REQUEST)

const FindMany = async (limit = -1, page = 0, filterBy = [], orderBy = []) =>
  webSocketClient.emitAsync(
    WS_API_EVENTS.OUTGOING.ACTIVITY_FIND_MANY,
    {
      limit,
      page,
      filterBy,
      orderBy
    }
  )

const OnUpdatedMany = listener => webSocketClient.socket.on(WS_API_EVENTS.INCOMING.ACTIVITY_UPDATED, listener)

const OffUpdatedMany = listener => webSocketClient.socket.off(WS_API_EVENTS.INCOMING.ACTIVITY_UPDATED, listener)

const ActivityManagementService = {
  RequestActivities: withStandardWSResponseHandling(RequestActivities),
  FindMany: withStandardWSResponseHandling(FindMany),
  OnUpdatedMany,
  OffUpdatedMany
}

export default ActivityManagementService
