import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import useViewModel from '../../../../../hooks/useViewModel'
import { COMMAND_NAME, COMMAND_NAMES } from '../../../../../constants/model'
import { formatEventDate } from '../../../../../utilities/date'
import { Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const executedCommandModelToExecutedCommandListRowViewModel = ({
  id,
  commandId,
  executionDate,
  parameters,
  Command,
  Agent,
  User,
  Process
}) => {
  const parsedParameters = JSON.parse(parameters)
  return ({
    id,
    actionId: commandId,
    actionName: COMMAND_NAME[Command?.name]?.label,
    agentName: Agent?.name || '?',
    serverID: Agent?.serverId || '?',
    userName: User ? `${User.firstName} ${User.lastName}` : '?',
    processName: Process?.name,
    ip: parsedParameters?.ip,
    executionDate: formatEventDate(new Date(executionDate))
  })
}

const ExecutedCommandListRow = ({ row, onRowClick }) => {
  const executedCommandView = useViewModel(row, executedCommandModelToExecutedCommandListRowViewModel)

  const isIPCommand = [COMMAND_NAMES.UNBAN_IP].includes(executedCommandView.actionId)
  const isProcessCommand = [
    COMMAND_NAMES.START_PROCESS, COMMAND_NAMES.STOP_PROCESS, COMMAND_NAMES.RESTART_PROCESS
  ].includes(executedCommandView.actionId)

  return (
    <TableRow
      hover
      onClick={event => onRowClick(event, executedCommandView.id)}
      role='checkbox'
      tabIndex={-1}
      key={executedCommandView.id}
      selected={false}
    >
      <TableCell>{executedCommandView.actionName}</TableCell>
      <TableCell>
        {isIPCommand && (
          <Typography variant='body2'>
            <FormattedMessage
              id='ExecutedCommandListRow.IPLabel'
              defaultMessage='IP'
            />: <b>{executedCommandView.ip}</b>
          </Typography>
        )}
        {isProcessCommand && (
          <Typography variant='body2'>
            <FormattedMessage
              id='ExecutedCommandListRow.ProcessLabel'
              defaultMessage='Process'
            />: <b>{executedCommandView.processName}</b>
          </Typography>
        )}
      </TableCell>
      <TableCell>{executedCommandView.serverID}({executedCommandView.agentName})</TableCell>
      <TableCell>{executedCommandView.userName}</TableCell>
      <TableCell>{executedCommandView.executionDate}</TableCell>
    </TableRow>
  )
}

export default React.memo(ExecutedCommandListRow)
