import webSocketClient from '../clients/webSocketClient'
import { WS_API_EVENTS } from '../constants/api'
import { withStandardWSResponseHandling } from '../utilities/responseHandling'

const FindMany = async (limit = -1, page = 0, filterBy = [], orderBy = []) =>
  webSocketClient.emitAsync(
    WS_API_EVENTS.OUTGOING.PROCESS_STATUS_FIND_MANY,
    {
      limit,
      page,
      filterBy,
      orderBy
    }
  )

const ProcessStatusService = {
  FindMany: withStandardWSResponseHandling(FindMany)
}

export default ProcessStatusService
