import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAgentActivitiesList } from '../../../redux/selectors/AgentActivities'
import { requestActivities } from '../../../redux/actions/AgentActivities'
import ActivityItem from '../components/ActivityItem'
import GridContainer from '../../../@jumbo/components/GridContainer'
import MultilineChartIcon from '@material-ui/icons/MultilineChart'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { selectAgentProcessesList } from '../../../redux/selectors/AgentProcesses'
import { fetchAgentProcesses } from '../../../redux/actions/AgentProcesses'
import PATHS from '../../paths'
import { createSearchParams, useNavigate } from 'react-router-dom'

const activityModelToActivityViewModel = ({
    agentId,
    activity: {
      totalNrOfUsers,
      nrOfActiveUsers,
      nrOfDevicesInactiveSinceLastMonth,
      nrOfDevicesInactiveSinceLastWeek,
      nrOfGPSTrackersInactiveSinceLastMonth,
      nrOfGPSTrackersInactiveSinceLastWeek,
      nrOfUsersInactiveSinceLastMonth,
      nrOfUsersInactiveSinceLastWeek,
      totalNrOfDevices,
      totalNrOfGPSTrackers,
      nrOfActiveDevices,
      nrOfActiveGPSTrackers
    }
  },
  agentName
) => ({
  agentId,
  agentName,
  totalNrOfUsers,
  nrOfActiveUsers,
  nrOfDevicesInactiveSinceLastMonth,
  nrOfDevicesInactiveSinceLastWeek,
  nrOfGPSTrackersInactiveSinceLastMonth,
  nrOfGPSTrackersInactiveSinceLastWeek,
  nrOfUsersInactiveSinceLastMonth,
  nrOfUsersInactiveSinceLastWeek,
  totalNrOfDevices,
  totalNrOfGPSTrackers,
  nrOfActiveDevices,
  nrOfActiveGPSTrackers
})

const ProcessActivities = () => {
  const dispatch = useDispatch()
  const agentActivities = useSelector(selectAgentActivitiesList)
  const agentProcesses = useSelector(selectAgentProcessesList)
  const navigate = useNavigate()

  const processActivityViewModels = useMemo(
    () => {
      if (agentProcesses?.length && agentActivities?.length) {
        return agentActivities.map(agentActivity => {
          const relatedAgentName = agentProcesses.find(agent => agent.id === agentActivity.agentId)?.Processes?.map(process => process.name).join(', ') || 'Unknown process name'
          return activityModelToActivityViewModel(agentActivity, relatedAgentName)
        })
      }

      return []
    },
    [agentActivities, agentProcesses]
  )

  useEffect(() => {
    dispatch(fetchAgentProcesses())
    dispatch(requestActivities())
  }, [dispatch])

  const handleStatsClicked = useCallback(
    (agentId, statisticsCategory) => () => navigate({
      pathname: `/${PATHS.PROCESS_ACTIVITY.STATISTICS}`,
      search: createSearchParams({
        agentId,
        statisticsCategory
      }).toString()
    }),
    [navigate]
  )

  return processActivityViewModels?.length
    ? processActivityViewModels.map(
      processActivityViewModel => (
        <React.Fragment key={processActivityViewModel.agentId}>
          <Box display="flex" flexDirection="row" alignItems="center" mt={4} mb={4}>
            <MultilineChartIcon />
            <Typography variant="h2">
              Activities for - "{processActivityViewModel.agentName}"
            </Typography>
          </Box>
          <GridContainer spacing={2}>
            <ActivityItem
              totalNrOfUsers={processActivityViewModel.totalNrOfUsers}
              nrOfActiveUsers={processActivityViewModel.nrOfActiveUsers}
              nrOfDevicesInactiveSinceLastMonth={processActivityViewModel.nrOfDevicesInactiveSinceLastMonth}
              nrOfDevicesInactiveSinceLastWeek={processActivityViewModel.nrOfDevicesInactiveSinceLastWeek}
              nrOfGPSTrackersInactiveSinceLastMonth={processActivityViewModel.nrOfGPSTrackersInactiveSinceLastMonth}
              nrOfGPSTrackersInactiveSinceLastWeek={processActivityViewModel.nrOfGPSTrackersInactiveSinceLastWeek}
              nrOfUsersInactiveSinceLastMonth={processActivityViewModel.nrOfUsersInactiveSinceLastMonth}
              nrOfUsersInactiveSinceLastWeek={processActivityViewModel.nrOfUsersInactiveSinceLastWeek}
              totalNrOfDevices={processActivityViewModel.totalNrOfDevices}
              totalNrOfGPSTrackers={processActivityViewModel.totalNrOfGPSTrackers}
              nrOfActiveDevices={processActivityViewModel.nrOfActiveDevices}
              nrOfActiveGPSTrackers={processActivityViewModel.nrOfActiveGPSTrackers}
              onUserActivityStatsClicked={handleStatsClicked(processActivityViewModel.agentId, 'users')}
              onDeviceActivityStatsClicked={handleStatsClicked(processActivityViewModel.agentId, 'devices')}
              onTrackerActivityStatsClicked={handleStatsClicked(processActivityViewModel.agentId, 'gpsTrackers')}
            />
          </GridContainer>
        </React.Fragment>
      )
    )
    : (
      <p>No activity data was found</p>
    )
}

export default ProcessActivities
