import { put, takeLatest } from 'redux-saga/effects'
import { fetchStart, fetchSuccess } from '../actions'
import { handleRemoteApiError } from '../../utilities/responseHandling'
import AGENT_PROCESSES_TYPES from '../types/AgentProcesses'
import { setAgentProcesses } from '../actions/AgentProcesses'
import AgentService from '../../services/Agent'

function * fetchAgentProcesses () {
  try {
    yield put(fetchStart('agentProcesses'))
    const { items: agentProcesses } = yield AgentService.WithProcessesFindMany()
    // TODO: read data with general list data reader: here(in the saga layer) or in the service layer?

    yield put(setAgentProcesses(agentProcesses))
    yield put(fetchSuccess(undefined, 'agentProcesses'))
  } catch (e) {
    yield handleRemoteApiError(e, 'agentProcesses', 'Failed to fetch agent processes')
  }
}

function * AgentProcessesSaga () {
  yield takeLatest(AGENT_PROCESSES_TYPES.FETCH, fetchAgentProcesses)
}

export default AgentProcessesSaga
