import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Error404 from './Admin/404'
import Login from './Auth/Login'
import ForgotPasswordPage from './Auth/ForgotPassword'
import Admin from './Admin'
import PATHS from './paths'
import { selectAuth } from '../redux/selectors/Auth'

const AuthBasedRouteRestrictor = ({ children }) => {
  const { authUser } = useSelector(selectAuth)
  const location = useLocation()

  if (!authUser) {
    return (
      <Navigate
        to={PATHS.AUTH.LOGIN}
        state={{ from: location.pathname }}
      />
    )
  }

  return children
}

const AppRoutes = () => {
  const { authUser } = useSelector(selectAuth)
  const location = useLocation()

  if (authUser && [PATHS.AUTH.LOGIN, PATHS.AUTH.FORGOT_PASSWORD, PATHS.ROOT].includes(location.pathname)) {
    return (
      <Navigate
        to={`/${PATHS.DASHBOARD}`}
        replace
      />
    )
  }

  return (
    <Routes>
      <Route path={PATHS.AUTH.LOGIN} element={<Login />} />
      <Route path={PATHS.AUTH.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
      <Route
        path={`${PATHS.ROOT}*`}
        element={(
          <AuthBasedRouteRestrictor>
            <Admin />
          </AuthBasedRouteRestrictor>
        )}
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  )
}

export default AppRoutes
