import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAgentActivitiesList } from '../../../redux/selectors/AgentActivities'
import { requestActivities } from '../../../redux/actions/AgentActivities'
import ActivityItem from '../components/ActivityItem'
import { selectCurrentProcess } from '../../../redux/selectors/Processes'
import { setCurrentProcess } from '../../../redux/actions/Processes'
import GridContainer from '../../../@jumbo/components/GridContainer'
import Box from '@material-ui/core/Box'
import MultilineChartIcon from '@material-ui/icons/MultilineChart'
import Typography from '@material-ui/core/Typography'
import PATHS from '../../paths'
import { createSearchParams, useNavigate } from 'react-router-dom'

const activityModelToActivityViewModel = ({
  totalNrOfUsers,
  nrOfActiveUsers,
  nrOfDevicesInactiveSinceLastMonth,
  nrOfDevicesInactiveSinceLastWeek,
  nrOfGPSTrackersInactiveSinceLastMonth,
  nrOfGPSTrackersInactiveSinceLastWeek,
  nrOfUsersInactiveSinceLastMonth,
  nrOfUsersInactiveSinceLastWeek,
  totalNrOfDevices,
  totalNrOfGPSTrackers,
  nrOfActiveDevices,
  nrOfActiveGPSTrackers
}) => ({
  totalNrOfUsers,
  nrOfActiveUsers,
  nrOfDevicesInactiveSinceLastMonth,
  nrOfDevicesInactiveSinceLastWeek,
  nrOfGPSTrackersInactiveSinceLastMonth,
  nrOfGPSTrackersInactiveSinceLastWeek,
  nrOfUsersInactiveSinceLastMonth,
  nrOfUsersInactiveSinceLastWeek,
  totalNrOfDevices,
  totalNrOfGPSTrackers,
  nrOfActiveDevices,
  nrOfActiveGPSTrackers
})

const ProcessActivity = () => {
  const dispatch = useDispatch()
  const currentProcess = useSelector(selectCurrentProcess)
  const agentActivities = useSelector(selectAgentActivitiesList)
  const navigate = useNavigate()

  // useEffect(() => () => dispatch(setCurrentProcess(null)), [dispatch]) // reset current process when unmounting the page

  const currentProcessActivity = useMemo(
    () => {
      const activity = agentActivities.find(agentActivity => agentActivity.agentId === currentProcess?.Agent?.id)
      return activity && activityModelToActivityViewModel(activity.activity)
    },
    [agentActivities, currentProcess]
  )

  useEffect(() => {
    if (currentProcess && !currentProcessActivity) {
      dispatch(requestActivities())
    }
  }, [dispatch, currentProcessActivity, currentProcess])

  const handleUserStatsClicked = useCallback(
    () => navigate({
      pathname: `/${PATHS.PROCESS_ACTIVITY.STATISTICS}`,
      search: createSearchParams({
        agentId: currentProcess?.Agent?.id,
        statisticsCategory: 'users'
      }).toString()
    }),
    [navigate, currentProcess?.Agent?.id]
  )

  const handleDeviceStatsClicked = useCallback(
    () => navigate({
      pathname: `/${PATHS.PROCESS_ACTIVITY.STATISTICS}`,
      search: createSearchParams({
        agentId: currentProcess?.Agent?.id,
        statisticsCategory: 'devices'
      }).toString()
    }),
    [navigate, currentProcess?.Agent?.id]
  )

  const handleTrackerStatsClicked = useCallback(
    () => navigate({
      pathname: `/${PATHS.PROCESS_ACTIVITY.STATISTICS}`,
      search: createSearchParams({
        agentId: currentProcess?.Agent?.id,
        statisticsCategory: 'gpsTrackers'
      }).toString()
    }),
    [navigate, currentProcess?.Agent?.id]
  )

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center" mt={4} mb={4}>
        <MultilineChartIcon />
        <Typography variant="h2">
          Activities for - "{currentProcess?.name}"
        </Typography>
      </Box>
      <GridContainer spacing={2}>
        {currentProcessActivity ? (
          <ActivityItem
            key="activity"
            totalNrOfUsers={currentProcessActivity.totalNrOfUsers}
            nrOfActiveUsers={currentProcessActivity.nrOfActiveUsers}
            nrOfDevicesInactiveSinceLastMonth={currentProcessActivity.nrOfDevicesInactiveSinceLastMonth}
            nrOfDevicesInactiveSinceLastWeek={currentProcessActivity.nrOfDevicesInactiveSinceLastWeek}
            nrOfGPSTrackersInactiveSinceLastMonth={currentProcessActivity.nrOfGPSTrackersInactiveSinceLastMonth}
            nrOfGPSTrackersInactiveSinceLastWeek={currentProcessActivity.nrOfGPSTrackersInactiveSinceLastWeek}
            nrOfUsersInactiveSinceLastMonth={currentProcessActivity.nrOfUsersInactiveSinceLastMonth}
            nrOfUsersInactiveSinceLastWeek={currentProcessActivity.nrOfUsersInactiveSinceLastWeek}
            totalNrOfDevices={currentProcessActivity.totalNrOfDevices}
            totalNrOfGPSTrackers={currentProcessActivity.totalNrOfGPSTrackers}
            nrOfActiveDevices={currentProcessActivity.nrOfActiveDevices}
            nrOfActiveGPSTrackers={currentProcessActivity.nrOfActiveGPSTrackers}
            onUserActivityStatsClicked={handleUserStatsClicked}
            onDeviceActivityStatsClicked={handleDeviceStatsClicked}
            onTrackerActivityStatsClicked={handleTrackerStatsClicked}
          />
        ) : (
          <p>No activity data was found.</p>
        )}
      </GridContainer>
    </>
  )
}

export default ProcessActivity
