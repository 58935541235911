import React, { useCallback, useMemo } from 'react'
import CmtCard from '../../../../../@coremat/CmtCard'
import CmtCardHeader from '../../../../../@coremat/CmtCard/CmtCardHeader'
import { Box, Button, IconButton, Link, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from './style'
import PropTypes from 'prop-types'
import useViewModel from '../../../../../hooks/useViewModel'
import { FormattedMessage, FormattedRelativeTime } from 'react-intl'
import CmtCardContent from '../../../../../@coremat/CmtCard/CmtCardContent'
import Settings from '@material-ui/icons/Settings'
import Stop from '@material-ui/icons/Stop'
import Refresh from '@material-ui/icons/Refresh'
import PlayCircleFilledOutlined from '@material-ui/icons/PlayCircleFilledOutlined'
import SdCardIcon from '@material-ui/icons/SdCard'
import MemoryIcon from '@material-ui/icons/Memory'
import TimelineIcon from '@material-ui/icons/Timeline'
import MultilineChartIcon from '@material-ui/icons/MultilineChart'
import { AGENT_CONNECTION_STATUS, PROCESS_RUN_STATUS, PROCESS_SOCKET_STATUS } from '../../../../../constants/model'
import { differenceInSeconds } from '../../../../../utilities/date'
import { useDispatch } from 'react-redux'
import { restartProcess, startProcess, stopProcess } from '../../../../../redux/actions/Processes'
import { BYTE_TO_MB } from '../../../../../constants/general'

const useStyles = makeStyles(styles)

const processModelToProcessCardViewModel = ({
  id,
  name,
  ProcessStatuses,
  Agent
}) => {
  const latestStatus = ProcessStatuses && ProcessStatuses[0]

  return ({
    id,
    name,
    isRunning: latestStatus?.isRunning,
    cpuUsage: latestStatus?.cpuUsage,
    memoryUsage: latestStatus?.memoryUsage ? (latestStatus?.memoryUsage / BYTE_TO_MB).toFixed(2) : 0,
    uptime: latestStatus?.uptime
      ? differenceInSeconds(new Date(latestStatus.uptime), Date.now())
      : null,
    isSocketWorking: latestStatus?.socketWorking,
    openSockets: latestStatus?.nrOfOpenSockets,
    nrOfTooManyDBConnectionsError: latestStatus?.nrOfTooManyDBConnectionsError,
    lastUpdate: latestStatus?.createdAt
      ? new Date(latestStatus.createdAt)
      : null,
    Agent
  })
}

const ProcessCard = ({ process, onProcessEdit, onProcessStatsClicked, onActivityClicked, onAgentEdit }) => {
  const dispatch = useDispatch()
  const processVM = useViewModel(process, processModelToProcessCardViewModel)
  const secondsPassedSinceLastUpdate = useMemo(
    () => processVM.lastUpdate && (
      /* a random fraction of seconds is added to the real difference to force the rerender of FormattedRelativeTime */
      differenceInSeconds(processVM.lastUpdate, Date.now()) - Math.random()
    ),
    [processVM.lastUpdate]
  )
  const classes = useStyles({
    isRunning: processVM.isRunning,
    isAgentConnected: processVM.Agent.isConnected,
    isSocketWorking: processVM.isSocketWorking
  })

  const handleStartProcess = useCallback(() => dispatch(startProcess(process.id)), [process.id, dispatch])

  const handleStopProcess = useCallback(() => dispatch(stopProcess(process.id)), [process.id, dispatch])

  const handleRestartProcess = useCallback(() => dispatch(restartProcess(process.id)), [process.id, dispatch])

  const handleProcessEdit = useCallback(() => onProcessEdit && onProcessEdit(process), [process, onProcessEdit])

  const handleProcessStatsClicked = useCallback(() => onProcessStatsClicked && onProcessStatsClicked(process), [process, onProcessStatsClicked])

  const handleActivityClicked = useCallback(() => onActivityClicked && onActivityClicked(process), [process, onActivityClicked])

  const handleAgentEdit = useCallback(() => onAgentEdit && onAgentEdit(process.Agent), [process.Agent, onAgentEdit])

  const StatusTitleElement = useMemo(() => process.Agent.isConnected
    ? AGENT_CONNECTION_STATUS.CONNECTED.label
    : AGENT_CONNECTION_STATUS.UNCONNECTED.label, [process.Agent.isConnected])

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader
        title={(
          <>
            <Typography variant='h4'>
              {processVM.name}
            </Typography>
            <Box display='flex' alignItems='center'>
              (
              <StatusTitleElement>
                {title => (
                  <span
                    className={classes.statusBadge}
                    title={title}
                  />
                )}
              </StatusTitleElement>
              <Link component='button' variant='body2' href='#' onClick={handleAgentEdit}>
                {process.Agent.name}
              </Link>
              )
            </Box>
          </>
        )}
      >
        <IconButton color='primary' aria-label='settings' component='span' onClick={handleProcessEdit}>
          <Settings />
        </IconButton>
        <IconButton color='primary' aria-label='statistics' component='span' onClick={handleProcessStatsClicked}>
          <TimelineIcon />
        </IconButton>
        <IconButton color='primary' aria-label='statistics' component='span' onClick={handleActivityClicked}>
          <MultilineChartIcon />
        </IconButton>
      </CmtCardHeader>
      <CmtCardContent>
        <Box flex={1} ali='center' display='flex' flexDirection='column'>
          <Box mb={4} display='flex' alignItems='center' flexDirection='column' justifyContent='center' fontSize={15}>
            <Box mb={1}>
              <FormattedMessage
                id='ProcessCard.lastUpdateLabel'
                defaultMessage='Last update'
              />
              {': '}
              <b>
                {secondsPassedSinceLastUpdate || secondsPassedSinceLastUpdate === 0
                  ? (
                    <FormattedRelativeTime
                      value={secondsPassedSinceLastUpdate}
                      numeric='auto'
                      updateIntervalInSeconds={1}
                    />
                    )
                  : '-'}
              </b>
            </Box>
            <Box mb={1}>
              <FormattedMessage
                id='ProcessCard.statusLabel'
                defaultMessage='Status'
              />
              {': '}
              <b className={classes.status}>
                {processVM.Agent.isConnected
                  ? processVM.isRunning
                      ? PROCESS_RUN_STATUS.RUNNING.label
                      : PROCESS_RUN_STATUS.STOPPED.label
                  /* when agent isn't online, run status couldn't be determined, so status is unknown */
                  : PROCESS_RUN_STATUS.UNKNOWN.label}
              </b>
            </Box>
            {processVM.Agent.isConnected && process.socketMonitoringEnabled && processVM.isRunning && (
              <Box mb={1}>
                <FormattedMessage
                  id='ProcessCard.socketStatusLabel'
                  defaultMessage='Socket status'
                />
                {': '}
                <b className={classes.socketStatus}>
                  {processVM.isSocketWorking === PROCESS_SOCKET_STATUS.RUNNING.value
                    ? PROCESS_SOCKET_STATUS.RUNNING.label
                    : processVM.isSocketWorking === PROCESS_SOCKET_STATUS.STOPPED.value
                      ? PROCESS_SOCKET_STATUS.STOPPED.label
                      : PROCESS_RUN_STATUS.UNKNOWN.label}
                </b>
              </Box>
            )}
            {processVM.Agent.isConnected && processVM.isRunning && process.socketMonitoringEnabled && processVM.isSocketWorking === PROCESS_SOCKET_STATUS.RUNNING.value && (
              <Box mb={1}>
                <FormattedMessage
                  id='ProcessCard.nrOfOpenSockets'
                  defaultMessage='Open sockets'
                />
                {': '}
                <b>
                  {processVM.openSockets}
                </b>
              </Box>
            )}
            {processVM.Agent.isConnected && processVM.isRunning && (
              <Box mb={1}>
                <FormattedMessage
                  id='ProcessCard.nrOfTooManyConnectionsError'
                  defaultMessage='Nr of TMC errors'
                />
                {': '}
                <b>
                  {processVM.nrOfTooManyDBConnectionsError}
                </b>
              </Box>
            )}
            {processVM.Agent.isConnected && processVM.isRunning && (
              <Box mb={1}>
                <FormattedMessage
                  id='ProcessCard.uptimeLabel'
                  defaultMessage='Started'
                />
                {': '}
                <b>
                  {processVM.uptime
                    ? (
                      <FormattedRelativeTime
                        value={processVM.uptime}
                        numeric='auto'
                        updateIntervalInSeconds={1}
                      />
                      )
                    : '?'}
                </b>
              </Box>
            )}
            <Box mb={1} display='flex' justifyContent='center'>
              <FormattedMessage
                id='ProcessCard.memoryLabel'
                defaultMessage='Memory consumption'
              >
                {message => (
                  <Box display='flex' title={message} mr={1}>
                    <SdCardIcon fontSize='small' />
                    {processVM.memoryUsage}
                    MB
                  </Box>
                )}
              </FormattedMessage>
              <FormattedMessage
                id='ProcessCard.cpuLabel'
                defaultMessage='CPU usage'
              >
                {message => (
                  <Box display='flex' title={message}>
                    <MemoryIcon fontSize='small' />
                    {processVM.cpuUsage}
                    %
                  </Box>
                )}
              </FormattedMessage>
            </Box>
          </Box>
          <Box display='flex' justifyContent='center'>
            <Box mr={1}>
              <Button
                color='primary'
                variant='contained'
                disabled={!processVM.Agent.isConnected}
                startIcon={processVM.isRunning ? <Refresh /> : <PlayCircleFilledOutlined />}
                onClick={processVM.isRunning ? handleRestartProcess : handleStartProcess}
              >
                {processVM.isRunning
                  ? (
                    <FormattedMessage
                      id='ProcessStatus.restartButtonLabel'
                      defaultMessage='Restart'
                    />
                    )
                  : (
                    <FormattedMessage
                      id='ProcessStatus.startButtonLabel'
                      defaultMessage='Start'
                    />
                    )}
              </Button>
            </Box>
            <Button
              color='secondary'
              variant='contained'
              disabled={!processVM.Agent.isConnected || !processVM.isRunning}
              startIcon={<Stop />}
              onClick={handleStopProcess}
            >
              <FormattedMessage
                id='ProcessStatus.stopButtonLabel'
                defaultMessage='Stop'
              />
            </Button>
          </Box>
        </Box>
      </CmtCardContent>
    </CmtCard>
  )
}

ProcessCard.propTypes = {
  process: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    ProcessStatuses: PropTypes.arrayOf(
      PropTypes.shape({
        isRunning: PropTypes.bool.isRequired,
        cpuUsage: PropTypes.number.isRequired,
        memoryUsage: PropTypes.number.isRequired,
        createdAt: PropTypes.string.isRequired
      })
    ).isRequired
  }),
  onProcessEdit: PropTypes.func,
  onProcessStatsClicked: PropTypes.func,
  onActivityClicked: PropTypes.func,
  onAgentEdit: PropTypes.func
}

export default React.memo(ProcessCard)
