class SortParameter {

  static SORT_TYPES = {
    ASC: 'ASC',
    DESC: 'DESC'
  }

  constructor (attributeName, sortType) {
    const allowedSortTypes = Object.values(SortParameter.SORT_TYPES)

    if (!allowedSortTypes.includes(sortType)) {
      throw new Error('[SortParameter] Invalid sort type: ', sortType)
    }

    this.attributeName = attributeName
    this.sortType = sortType
  }

  static FromPlainObject ({
    attributeName,
    sortType
  }) {
    return new SortParameter(attributeName, sortType)
  }

  static ParsePlainSortParametersIntoSortParameterCollection (plainSortParameters) {
    return plainSortParameters
      .map(SortParameter.FromPlainObject)
  }

  toPlainObject () {
    return {
      attributeName: this.attributeName,
      sortType: this.sortType
    }
  }

  static SortParameterCollectionToPlainSortParameterCollection (sortParameterCollection) {
    return sortParameterCollection
      .map(sortParameter => sortParameter.toPlainObject())
  }
}

export default SortParameter
