export default theme => ({
  dialogRoot: {
    position: 'relative'
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  }
})
