import LogService from '../../services/log'
import { put } from '@redux-saga/core/effects'
import { fetchError } from '../../redux/actions'
import ERROR_CODES from '../../constants/errorCodes'
import { WS_EVENT_RESPONSE_TYPES } from '../../constants/api'
import WSErrorResponse from './WSErrorResponse'

export function * handleHTTPError (error, fetchId, logMessage) {
  yield LogService.error(logMessage, error?.response?.data || error)
  // TODO: extract and show error reference from error?.response?.data?.data?.details
  yield put(
    fetchError(
      error?.response?.data?.data?.code || ERROR_CODES.DEFAULT,
      fetchId
    )
  )
}

export function * handleRemoteApiError (error, fetchId, logMessage) {
  let message = ERROR_CODES.DEFAULT
  // let reference = null
  if (error instanceof WSErrorResponse) {
    message = error.details?.code
    // reference = error.details?.details?.reference
  }

  yield LogService.error(logMessage, JSON.stringify(error))
  yield put(
    fetchError(
      // TODO: refactor fetch error renderer component to support objects so error reference can be displayed to the user
      message,
      fetchId
    )
  )
}

/**
 * Handles ws event responses with the format {
 *  type: { "SUCCESS" | "ERROR" },
 *  data: { object }
 * }.
 * WS event emitters can be enhanced with auto-handling of the standard ws response object, by being wrapped with this
 * utility function.
 * @param wsEventEmitter {function}: wrapped ws event emitter.
 * @returns {Promise<*>}:
 * - wsEventResult if wsEventResult hasn't got the standard format handled by this utility function.
 * - wsEventResult.data if wsEventResult\.type === WS_EVENT_RESPONSE_TYPES.SUCCESS and event was
 * successfully emitted.
 * @throws Error if wsEventResult\.type === WS_EVENT_RESPONSE_TYPES.ERROR or an error occurs during the execution of the
 * event emitter.
 */
export const withStandardWSResponseHandling = wsEventEmitter => async (...args) => {
  const result = await wsEventEmitter(...args)
  switch (result.type) {
    case WS_EVENT_RESPONSE_TYPES.SUCCESS:
      return result.data
    case WS_EVENT_RESPONSE_TYPES.ERROR:
      throw new WSErrorResponse('WS event error:', result.data)
    default:
      return result
  }
}
