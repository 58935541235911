export default () => ({
  cardRoot: {
    padding: 5,
    marginBottom: 40,
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  cardHeaderRoot: {
    paddingLeft: 0
  }
})
