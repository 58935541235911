import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import { Box } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import CmtImage from '../../../../@coremat/CmtImage'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  logo: {
    borderRadius: 5,
    width: 50
  }
}))

const Logo = ({ color, ...props }) => {
  const logoUrl = '/logo198.png'
  const logoSymbolUrl = color === 'white' ? '/images/logo-white-symbol.png' : '/images/logo-symbol.png'
  const classes = useStyles()

  return (
    <Box className='pointer' {...props}>
      <Hidden xsDown>
        <NavLink to='/'>
          <CmtImage src={logoUrl} alt='logo' className={classes.logo} />
        </NavLink>
      </Hidden>
      <Hidden smUp>
        <NavLink to='/'>
          <CmtImage src={logoSymbolUrl} alt='logo' />
        </NavLink>
      </Hidden>
    </Box>
  )
}

export default Logo
