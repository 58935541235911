import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import useViewModel from '../../../../../hooks/useViewModel'
import CmtCard from '../../../../../@coremat/CmtCard'
import CmtCardHeader from '../../../../../@coremat/CmtCard/CmtCardHeader'
import style from './style'
import ProcessCard from '../ProcessCard'
import { makeStyles } from '@material-ui/core/styles'
import { AGENT_CONNECTION_STATUS } from '../../../../../constants/model'
import GridContainer from '../../../../../@jumbo/components/GridContainer'
import {
  Box,
  Grid,
  Typography
} from '@material-ui/core'
import Lock from '@material-ui/icons/Lock'
import JailItem from '../JailItem'
import { unbanIP } from '../../../../../redux/actions/AgentJails'
import { useDispatch } from 'react-redux'

const titleProps = {
  variant: 'h2',
  component: 'div'
}

const useStyles = makeStyles(style)

const serverModelToServerViewModel = ({
  id,
  Processes,
  Jails,
}) => ({
  id,
  Processes: Processes.map(process => ({
    ...process,
    Agent: {
      id: process.Agent.id,
      name: process.Agent.name,
      notificationsEnabled: process.Agent.notificationsEnabled,
      isConnected: process.Agent.connectionStatus === AGENT_CONNECTION_STATUS.CONNECTED.value
    }
  })),
  Jails: Jails.map(({ name, bannedIPS }) => ({ name, bannedIPS }))
})

const ServerItem = ({ server, onAgentEdit, onProcessEdit, onProcessStatsClicked, onActivityClicked }) => {
  const dispatch = useDispatch()

  const serverVm = useViewModel(server, serverModelToServerViewModel)
  const classes = useStyles()

  const handleIPUnban = useCallback(
    (jail, ip) => dispatch(
      unbanIP(server.id, jail, ip)
    ),
    [server.id, dispatch]
  )

  const renderProcess = useCallback(process => (
    <Grid key={process.id} item xs={12} md={4} xl={3}>
      <ProcessCard
        process={process}
        onProcessEdit={onProcessEdit}
        onProcessStatsClicked={onProcessStatsClicked}
        onActivityClicked={onActivityClicked}
        onAgentEdit={onAgentEdit}
      />
    </Grid>
  ), [onAgentEdit, onProcessEdit, onProcessStatsClicked, onActivityClicked])

  const renderJail = useCallback(jail => (
    <Grid key={jail.name} item xs={12} md={4} xl={3}>
      <JailItem
        name={jail.name}
        bannedIPS={jail.bannedIPS}
        onUnban={handleIPUnban}
      />
    </Grid>
  ), [handleIPUnban])

/*    {!!serverVm.Activities?.length && (
        <>
          <Box display="flex" flexDirection="row" alignItems="center" mt={4} mb={4}>
            <MultilineChart />
            <Typography variant="h2">
              Activity
            </Typography>
          </Box>
          <GridContainer spacing={2}>
            {serverVm.Activities.map(renderActivity)}
          </GridContainer>
        </>
      )}*/

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader
        className={classes.cardHeaderRoot}
        title={serverVm.id}
        titleProps={titleProps}
      />
      <GridContainer>
        {serverVm.Processes.map(renderProcess)}
      </GridContainer>
      {!!serverVm.Jails.length && (
        <>
          <Box display="flex" flexDirection="row" alignItems="center" mt={4} mb={4}>
            <Lock />
            <Typography variant="h2">
              IP Jails
            </Typography>
          </Box>
          <GridContainer spacing={2}>
            {serverVm.Jails.map(renderJail)}
          </GridContainer>
        </>
      )}
    </CmtCard>
  )
}

ServerItem.propTypes = {
  server: PropTypes.shape({
    id: PropTypes.string.isRequired,
    Processes: PropTypes.arrayOf(
      PropTypes.shape({
        Agent: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          connectionStatus: PropTypes.oneOf(Object.values(AGENT_CONNECTION_STATUS).map(({ value }) => value)).isRequired,
          Processes: PropTypes.arrayOf(PropTypes.object).isRequired
        })
      })
    ).isRequired,
    Jails: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  onAgentEdit: PropTypes.func,
  onProcessEdit: PropTypes.func,
  onProcessStatsClicked: PropTypes.func,
  onActivityClicked: PropTypes.func
}

export default React.memo(ServerItem)
